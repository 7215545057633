import clsx from 'clsx';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import * as Yup from 'yup';
import { RootState } from '../../../../setup';
import { countries } from '../../../data/country_list';
import { industries } from '../../../data/industries_list';
import { getCompany, updateCompany } from '../../../modules/CRUD/CRUD';
import { toast } from 'react-toastify'

function CompanyProfileEdit() {
	const { companyId } = useParams();
	invariant(companyId, 'Company id falsy in this route');
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	// const [company, setCompany] = React.useState<CompanyType | null>(null)
	const [loading, setLoading] = useState(false);
	const [initialValues, setInitialValues] = useState({
		name: '',
		size: '',
		website: '',
		isSmsEnabled: true,
		industry: '',
		country: '',
		rollover: 365,
	});

	const registrationSchema = Yup.object().shape({
		name: Yup.string()
						 .required('Company name is required'),
		size: Yup.string()
						 .required('Company size is required'),
		industry: Yup.string()
								 .required('Industry is required'),
		country: Yup.string()
								.required('Country is required'),
		rollover: Yup.string()
								 .required('Rollover is required'),
	});

	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema: registrationSchema,
		onSubmit: (values,
							 {
								 setStatus,
								 setSubmitting,
							 }) => {
			setLoading(true);
			setTimeout(() => {
				updateCompany(
					companyId,
					{
						name: values.name,
						size: values.size,
						website: values.website,
						industry: values.industry,
						country: values.country,
						rollover: values.rollover,
						isSmsEnabled: values.isSmsEnabled,
					},
					token,
				)
					.then(({ data }) => {
						setLoading(false);
						// history.push('/companies')
					})
					.catch((err) => {
						console.log(err);
            toast.error(err.response.data.message);
						setLoading(false);
					});
			}, 1000);
		},
	});

	useEffect(() => {
		getCompany(companyId, token)
			.then(({ data }) => {
				// setCompany(data)
				setInitialValues({
					name: data.name,
					size: data.size,
					website: data.website,
					isSmsEnabled: data.isSmsEnabled,
					industry: data.industry,
					country: data.country,
					rollover: data.rollover,
				});
			});
	}, [companyId, token]);

	return (
		<>
			<div className="card edit_company_card">
				<div className="card-header border-0 pt-5"></div>
				<div className="card-body py-3">
					<div className="p-lg-15 p-10 bg-white rounded mx-auto">
						<form
							className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
							noValidate
							id="kt_login_signup_form"
							onSubmit={formik.handleSubmit}

						>
							<div className="row fv-row mb-7">
								<label className="col-lg-4 col-form-label fw-bolder text-dark fs-6">Company Name</label>
								<div className="col-lg-8">
									<input
										placeholder="Company Name"
										type="text"
										autoComplete="off"
										{...formik.getFieldProps('name')}
										className={clsx(
											'form-control form-control-lg form-control-solid',
											{
												'is-invalid': formik.touched.name && formik.errors.name,
											},
											{
												'is-valid': formik.touched.name && !formik.errors.name,
											},
										)}
									/>
									{formik.touched.name && formik.errors.name && (
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">
												<span role="alert">{formik.errors.name}</span>
											</div>
										</div>
									)}
								</div>
							</div>

							<div className="row fv-row mb-7">
								<label className="col-lg-4 col-form-label fw-bolder text-dark fs-6">Company Size</label>
								<div className="col-lg-8">
									<select
										{...formik.getFieldProps('size')}
										className={clsx(
											'form-control form-control-lg form-control-solid',
											// {
											//     'is-invalid': formik.touched.role && formik.errors.role,
											// },
											// {
											//     'is-valid': formik.touched.role && !formik.errors.role,
											// }
										)}
										style={{ appearance: 'auto' }}
									>
										<option value=""></option>
										<option value="Small">0-50</option>
										<option value="Medium">51-250</option>
										<option value="Large">251-499</option>
										<option value="Enterprise">500+</option>
									</select>
									{formik.touched.size && formik.errors.size && (
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">
												<span role="alert">{formik.errors.size}</span>
											</div>
										</div>
									)}
								</div>
							</div>

							<div className="row fv-row mb-7">
								<label className="col-lg-4 col-form-label fw-bolder text-dark fs-6">Website</label>
								<div className="col-lg-8">
									<input
										placeholder="Website"
										type="text"
										autoComplete="off"
										{...formik.getFieldProps('website')}
										className={clsx(
											'form-control form-control-lg form-control-solid',
											{
												'is-invalid': formik.touched.website && formik.errors.website,
											},
											{
												'is-valid': formik.touched.website && !formik.errors.website,
											},
										)}
									/>
									{formik.touched.website && formik.errors.website && (
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">
												<span role="alert">{formik.errors.website}</span>
											</div>
										</div>
									)}
								</div>
							</div>

							<div className="row fv-row mb-7">
								<label className="col-lg-4 col-form-label fw-bolder text-dark fs-6">Industry</label>
								<div className="col-lg-8">
									<select
										{...formik.getFieldProps('industry')}
										className={clsx(
											'form-control form-control-lg form-control-solid',
										)}
										style={{ appearance: 'auto' }}
									>
										<option value=""></option>
										{
											industries.map(industry => (
												<option
													key={industry}
													value={industry}>{industry}</option>
											))
										}
									</select>
									{formik.touched.industry && formik.errors.industry && (
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">
												<span role="alert">{formik.errors.industry}</span>
											</div>
										</div>
									)}
								</div>
							</div>

							<div className="row fv-row mb-7">
								<label className="col-lg-4 col-form-label fw-bolder text-dark fs-6">Country</label>
								<div className="col-lg-8">
									<select
										{...formik.getFieldProps('country')}
										className={clsx(
											'form-control form-control-lg form-control-solid',
										)}
										style={{ appearance: 'auto' }}
									>
										<option value=""></option>
										{
											countries.map(country => (
												<option
													key={country}
													value={country}>{country}</option>
											))
										}
									</select>
									{formik.touched.country && formik.errors.country && (
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">
												<span role="alert">{formik.errors.country}</span>
											</div>
										</div>
									)}
								</div>
							</div>

							<div className="row fv-row mb-10">
								<label className="col-lg-4 col-form-label fw-bolder text-dark fs-6">Rollover</label>
								<div className="col-lg-8">
									<select
										{...formik.getFieldProps('rollover')}
										className={clsx(
											'form-control form-control-lg form-control-solid',
										)}
										style={{ appearance: 'auto' }}
									>
										<option value=""></option>
										<option value="90">3 Months</option>
										<option value="180">6 Months</option>
										<option value="365">12 Months</option>
										<option value="465">18 Months</option>
										<option value="730">24 Months</option>
									</select>
									{formik.touched.rollover && formik.errors.rollover && (
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">
												<span role="alert">{formik.errors.rollover}</span>
											</div>
										</div>
									)}
								</div>
							</div>

							<div className="row fv-row mb-7">
								<div className="col-lg-4"></div>
								<div className="col-lg-8 form-check form-check-custom form-check-solid">
									<input
										className="form-check-input"
										type="checkbox"
										id="flexCheckDefault"
										{...formik.getFieldProps('isSmsEnabled')}
										checked={formik.values.isSmsEnabled}
									/>
									<label
										className="form-check-label fw-bolder text-dark fs-6"
										htmlFor="flexCheckDefault">
										Enable SMS
									</label>
								</div>
							</div>


							<div className="text-center mt-10">
								<button
									type="submit"
									id="kt_sign_up_submit"
									className="btn btn-lg btn-primary min-w-150px mb-5"
									disabled={formik.isSubmitting || !formik.isValid}
								>
									{!loading && <span className="indicator-label">Update</span>}
									{loading && (
										<span
											className="indicator-progress"
											style={{ display: 'block' }}>
                                            Please wait...{' '}
											<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
									)}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}

export { CompanyProfileEdit };
