import React from 'react'
import invariant from "tiny-invariant";
import { DisciplineStepType } from '../../../types/DisciplineStepType'
import { DisciplineStepsList } from '../../modals/components/discipline_steps/DisciplineStepsList'
import { CreateDisciplineStep } from '../../modals/components/discipline_steps/CreateDisciplineStep'
import { EditDisciplineStep } from '../../modals/components/discipline_steps/EditDisciplineStep'
import { useParams } from 'react-router-dom'

export function DisciplineStepsEdit() {
    const { companyId } = useParams();
    invariant(companyId, 'Company id falsy in this route');
    const [editStep, setEditStep] = React.useState<DisciplineStepType | null>(null)
    const [showPage, setShowPage] = React.useState('list')

    return (
        <div className="card">
            <div className="card-body py-10">
                <div className="step_wrapper_3 min-h-300px">
                    {showPage === 'list' && <DisciplineStepsList setShowPage={setShowPage} tenantId={companyId} setEditStep={setEditStep} />}
                    {showPage === 'create' && <CreateDisciplineStep setShowPage={setShowPage} tenantId={companyId} />}
                    {showPage === 'edit' && !!editStep && <EditDisciplineStep setShowPage={setShowPage} editStep={editStep} />}
                </div>
            </div>
        </div>
    )
}
