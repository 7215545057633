import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik';
import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { KTSVG } from '../../../../_library/helpers';
import { RootState } from '../../../../setup';
import { countries } from '../../../data/country_list';
import { industries } from '../../../data/industries_list';
import { createCompany } from '../../../modules/CRUD/CRUD';
import { SuperadminSearch } from '../../companies/SuperadminSearch';
import moment from 'moment';

interface ICreateAccount {
	accountTeamSize: string;
	companyName: string;
	industry: string;
	country: string;
	rollover: string;
	attendanceStartDate: string;
}

const initialValues: ICreateAccount = {
	accountTeamSize: 'Small',
	companyName: '',
	industry: '',
	country: 'United States',
	rollover: '',
	attendanceStartDate: '',
};

const profileSchema = Yup.object().shape({
	// email: Yup.string()
	// .email('Wrong email format')
	// .min(3, 'Minimum 3 symbols')
	// .max(50, 'Maximum 50 symbols')
	// .required('Email is required')
	// .matches(/^(?!.*(@gmail.com|@hotmail.com|@outlook.com|@yahoo.com)).*$/, 'Not Business Email'),
	accountTeamSize: Yup.string()
											.required('Team size is required'),
	companyName: Yup.string()
									.required('Company name is required'),
	industry: Yup.string()
							 .required('Industry is required'),
	country: Yup.string()
							.required('Country is required'),
	rollover: Yup.string()
							 .required('Rollover is required'),
	attendanceStartDate: Yup.string()
													.required('Attendance start date is required'),
});

type Props = {
	superadminId: number,
	setSuperadminId: (id: number) => void,
	setCompanyId: (id: string) => void,
	goNext: () => void,
}

export function CompanyProfileStep({
																		 superadminId,
																		 setSuperadminId,
																		 setCompanyId,
																		 goNext,
																	 }: Props) {
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const [loading, setLoading] = useState(false);
	const [showSuperadminError, setShowSuperadminError] = useState(false);

	const handleSubmit = (values: ICreateAccount, actions: FormikValues) => {
		if (superadminId === 0) {
			setShowSuperadminError(true);
		} else {
			setLoading(true);
			createCompany(
				values.companyName,
				values.accountTeamSize,
				'',
				values.industry,
				values.country,
				'NoFaultAttendance',
				'NoFaultPerformance',
				values.rollover,
				superadminId,
				moment(values.attendanceStartDate, 'M/D/YY').format('YYYY-MM-DD'),
				token,
			)
				.then(({ data }) => {
					setCompanyId(data.id);
					goNext();
					setLoading(false);
					actions.setSubmitting(false);
				})
				.catch(err => {
					console.log(err.response);
					setLoading(false);
					actions.setSubmitting(false);
					toast.error(err.response.data.message);
				});
		}
	};

	useEffect(() => {
		if (superadminId !== 0) {
			setShowSuperadminError(false);
		}
	}, [superadminId]);

	return (
		<div className="w-100">
			<div className="fv-row mb-10">
				<label className="d-flex align-items-center fs-5 fw-bold mb-2">
					<span className="required">Search Superadmin</span>
				</label>
				<SuperadminSearch setSuperadminId={setSuperadminId} />
				{showSuperadminError && <div className="text-danger">
					Superadmin is required
				</div>}
			</div>
			<Formik
				validationSchema={profileSchema}
				initialValues={initialValues}
				onSubmit={handleSubmit}
			>
				{({
						submitForm,
						isValid,
						dirty,
						handleChange,
						values,
					}) => (
					<Form
						className="form py-5 w-100"
						noValidate
						id="kt_modal_create_onboarding_form">
						<div className="mb-10 fv-row">
							<label className="d-flex align-items-center form-label mb-3">
								Specify Team Size
							</label>

							<div
								className="row mb-2"
								data-kt-buttons="true">
								<div className="col">
									<Field
										type="radio"
										className="btn-check"
										name="accountTeamSize"
										value="Small"
										id="kt_account_team_size_select_1"
									/>
									<label
										className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
										htmlFor="kt_account_team_size_select_1"
									>
										<span className="fw-bolder fs-3">0-50</span>
									</label>
								</div>

								<div className="col">
									<Field
										type="radio"
										className="btn-check"
										name="accountTeamSize"
										value="Medium"
										id="kt_account_team_size_select_2"
									/>
									<label
										className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
										htmlFor="kt_account_team_size_select_2"
									>
										<span className="fw-bolder fs-3">51-250</span>
									</label>
								</div>

								<div className="col">
									<Field
										type="radio"
										className="btn-check"
										name="accountTeamSize"
										value="Large"
										id="kt_account_team_size_select_3"
									/>
									<label
										className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
										htmlFor="kt_account_team_size_select_3"
									>
										<span className="fw-bolder fs-3">251-499</span>
									</label>
								</div>

								<div className="col">
									<Field
										type="radio"
										className="btn-check"
										name="accountTeamSize"
										value="Enterprise"
										id="kt_account_team_size_select_4"
									/>
									<label
										className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
										htmlFor="kt_account_team_size_select_4"
									>
										<span className="fw-bolder fs-3">500+</span>
									</label>
								</div>
							</div>

							<div className="form-text">
								Customers will see this shortened version of your statement descriptor
							</div>
						</div>

						<div className="fv-row mb-10">
							<label className="d-flex align-items-center fs-5 fw-bold mb-2">
								<span className="required">Company Name</span>
							</label>

							<Field
								type="text"
								className="form-control form-control-lg form-control-solid"
								name="companyName"
								placeholder=""
							/>
							<div className="text-danger">
								<ErrorMessage name="companyName" />
							</div>
						</div>

						<div className="row mb-10">
							<div className="col-6 fv-row">
								<label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
									<span className="required">Industry</span>
								</label>

								<Field
									as="select"
									name="industry"
									className="form-select form-select-solid"
								>
									<option></option>
									{
										industries.map(industry => (
											<option
												key={industry}
												value={industry}>{industry}</option>
										))
									}
								</Field>
								<div className="text-danger">
									<ErrorMessage name="industry" />
								</div>
							</div>
							<div className="col-6 fv-row">
								<label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
									<span className="required">Country</span>
								</label>

								<Field
									as="select"
									name="country"
									className="form-select form-select-solid"
								>
									<option></option>
									{
										countries.map(country => (
											<option
												key={country}
												value={country}>{country}</option>
										))
									}
								</Field>
								<div className="text-danger">
									<ErrorMessage name="country" />
								</div>
							</div>
						</div>

						<div className="fv-row mb-10">
							<label className="d-flex align-items-center fs-5 fw-bold mb-2">
								<span className="required">Rollover Period</span>
							</label>

							<Field
								as="select"
								name="rollover"
								className="form-select form-select-solid"
							>
								<option value=""></option>
								<option value="3">3 Months</option>
								<option value="6">6 Months</option>
								<option value="12">12 Months</option>
								<option value="18">18 Months</option>
								<option value="24">24 Months</option>
							</Field>
							<div className="text-danger text-start">
								<ErrorMessage name="rollover" />
							</div>
						</div>

						<div className="fv-row mb-10">

							<label className="d-flex align-items-center fs-5 fw-bold mb-2">
								<span className="required">Attendance Streak Start Date</span>
							</label>

							<Field
								type="text"
								className="form-control form-control-lg form-control-solid"
								name="attendanceStartDate"
								placeholder="M/D/YY"
							/>
							<div className="form-text">
								Set to the oldest attendance record that will be entered. If no
								historical attendance entry, set to go-live/implementation
								date.
							</div>
							<div className="text-danger text-start">
								<ErrorMessage name="attendanceStartDate" />
							</div>
						</div>

						<div className="d-flex flex-stack pt-10">
							<div className="me-2">
								{/* <button
								 onClick={() => { }}
								 type='button'
								 className='btn btn-lg btn-light-primary me-3'
								 >
								 <KTSVG
								 path='/media/icons/duotune/arrows/arr063.svg'
								 className='svg-icon-4 me-1'
								 />
								 Back
								 </button> */}
							</div>

							<div>
								<button
									type="submit"
									className="btn btn-md btn-primary me-3"
								>
									{!loading &&(
										<span className="indicator-label">
											Continue
											<KTSVG
												path="/media/icons/duotune/arrows/arr064.svg"
												className="svg-icon-3 ms-2 me-0"
											/>
										</span>
									)}
									{loading && (
										<span
											className="indicator-progress"
											style={{ display: 'block' }}>
													Please wait...
													<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
											</span>
									)}
								</button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
}
