import React, { FC, useEffect, useRef, useState } from 'react'
import { KTSVG } from '../../../_library/helpers'
import { StepperComponent } from '../../../_library/assets/ts/components'
import { CompanyProfileStep } from './components/CompanyProfileStep'
import { DisciplineStep } from './components/DisciplineStep'
import { AttendanceStep } from './components/AttendanceStep'
import { InviteSupervisorStep } from './components/InviteSupervisorStep'

import './styles/create_company_modal.scss'
import CoachingStep from './components/CoachingStep'
import RecognitionStep from './components/RecognitionStep'

const stepperLabels = [
    {
        title: "Company Profile",
        desc: "Setup Your Company Details"
    },
    {
        title: "Discipline Steps",
        desc: "Setup Discipline Steps"
    },
    {
        title: "Attendance",
        desc: "Setup Company Attendance Policy"
    },
    {
        title: "Coaching",
        desc: "Setup Coaching Categories"
    },
    {
        title: "Recognition",
        desc: "Setup Recognition Categories"
    },
    {
        title: "Invite",
        desc: "Invite Manager & Supervisors"
    },
]

// type Props = {
//     superadminId: number,
//     afterComplete: () => void
// }

export const CreateCompanyModalForCompany: FC = () => {
    const [superadminId, setSuperadminId] = useState(0)
    const [companyId, setCompanyId] = useState('')
    const [currentStepNumber, setCurrentStepNumber] = useState(1)
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const btnCloseRef = useRef<HTMLDivElement>(null)

    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    const prevStep = () => {
        if (!stepper.current) {
            return
        }
        stepper.current.goPrev()
        setCurrentStepNumber(parseInt((document.querySelector('.stepper-item.current .stepper-number') as HTMLSpanElement)?.innerText))
    }

    const nextStep = () => {
        if (!stepper.current) {
            return
        }

        if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
            stepper.current.goNext()
            setCurrentStepNumber(parseInt((document.querySelector('.stepper-item.current .stepper-number') as HTMLSpanElement)?.innerText))
        } else {
            // stepper.current.goFirst()
            // btnCloseRef.current?.click()
            window.location.reload()
        }
    }

    useEffect(() => {
        if (!stepperRef.current) {
            return
        }

        loadStepper()
    }, [stepperRef])

    return (
        <>
            <div className='show' id='create_company_modal_for_company' aria-hidden='true' data-bs-backdrop="static" data-bs-focus="false">
                <div className='modal-dialog modal-dialog-centered mw-1000px'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2>Create Company</h2>

                            <div ref={btnCloseRef} className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                            </div>
                        </div>

                        <div className='modal-body py-lg-10 px-lg-10'>
                            <div
                                ref={stepperRef}
                                className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                                id='kt_modal_create_app_stepper'
                            >
                                <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
                                    <div className='stepper-nav ps-lg-10'>

                                        {
                                            stepperLabels.map((item, i) =>
                                                <div key={i} className={`stepper-item ${i === 0 ? 'current' : ''}`} data-kt-stepper-element='nav'>
                                                    <div className='stepper-line w-40px'></div>

                                                    <div className='stepper-icon w-40px h-40px'>
                                                        <i className='stepper-check fas fa-check'></i>
                                                        <span className='stepper-number'>{i + 1}</span>
                                                    </div>

                                                    <div className='stepper-label'>
                                                        <h3 className='stepper-title'>{item.title}</h3>

                                                        <div className='stepper-desc'>{item.desc}</div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>

                                <div className='flex-row-fluid py-lg-5 px-lg-15'>

                                    <div className='current' data-kt-stepper-element='content'>
                                        <CompanyProfileStep superadminId={superadminId} setSuperadminId={setSuperadminId} setCompanyId={setCompanyId} goNext={nextStep} />
                                    </div>

                                    <div data-kt-stepper-element='content'>
                                        <div className='w-100'>
                                            {companyId !== '' &&
                                                <DisciplineStep companyId={companyId} goNext={nextStep} />
                                            }
                                        </div>
                                    </div>

                                    <div data-kt-stepper-element='content'>
                                        <div className='w-100'>
                                            {companyId !== '' && currentStepNumber === 3 &&
                                                // <AttendanceStep companyId={'cd32ba77-7eeb-4fcd-be6c-5980a63c589e'} goNext={nextStep} goPrev={() => { }} />
                                                <AttendanceStep companyId={companyId} goNext={nextStep} goPrev={prevStep} />
                                            }
                                        </div>
                                    </div>

                                    <div data-kt-stepper-element='content'>
                                        <div className='w-100'>
                                            {companyId !== '' && currentStepNumber === 4 &&
                                                <CoachingStep tenantId={companyId} goNext={nextStep} goPrev={prevStep} />
                                            }
                                        </div>
                                    </div>

                                    <div data-kt-stepper-element='content'>
                                        <div className='w-100'>
                                            {companyId !== '' && currentStepNumber === 5 &&
                                                <RecognitionStep tenantId={companyId} goNext={nextStep} goPrev={prevStep} />
                                            }
                                        </div>
                                    </div>

                                    <div data-kt-stepper-element='content'>
                                        <div className='w-100 text-center'>
                                            {companyId !== '' && <InviteSupervisorStep companyId={companyId} goNext={nextStep} goPrev={prevStep} />}
                                        </div>
                                    </div>

                                    {/* {currentStepNumber !== 3 && <div className='d-flex flex-stack pt-10'>
                                    <div className='me-2'>
                                        <button
                                            onClick={prevStep}
                                            type='button'
                                            className='btn btn-lg btn-light-primary me-3'
                                            data-kt-stepper-action='previous'
                                        >
                                            <KTSVG
                                                path='/media/icons/duotune/arrows/arr063.svg'
                                                className='svg-icon-4 me-1'
                                            />
                                            Back
                                        </button>
                                    </div>

                                    <div>
                                        <button
                                            type='button'
                                            className='btn btn-lg btn-primary me-3'
                                            onClick={nextStep}
                                        >
                                            <span className='indicator-label'>
                                                {stepper.current?.currentStepIndex !==
                                                    stepper.current?.totatStepsNumber! - 1 && 'Continue'}
                                                {stepper.current?.currentStepIndex ===
                                                    stepper.current?.totatStepsNumber! - 1 && 'Submit'}
                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr064.svg'
                                                    className='svg-icon-3 ms-2 me-0'
                                                />
                                            </span>
                                        </button>
                                    </div>
                                </div>} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


