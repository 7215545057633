import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import {
	CellClickedEvent,
	GridOptions,
	RowClickedEvent,
	RowDoubleClickedEvent,
	SelectionChangedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Ref, useMemo } from 'react';

interface GridProps<T> {
	rowData: T[];
	cols: [];
	rowSelection?: 'single' | 'multiple';
	suppressCellFocus?: boolean;
	multiSelectWithClick?: boolean;
	onSelectionChanged?: (event: SelectionChangedEvent<T>) => void;
	onRowClicked?: (event: RowClickedEvent<T>) => void;
	onCellClicked?: (event: CellClickedEvent<T>) => void;
	onRowDoubleClicked?: (event: RowDoubleClickedEvent<T>) => void;
	gridRef?: Ref<AgGridReact>;
	gridOptions?: GridOptions<T>;
	gridWidth?: number | string;
	gridHeight?: number | string;
}

export function Grid<T>({
													rowData,
													cols,
													suppressCellFocus = true,
													multiSelectWithClick = false,
													rowSelection,
													onSelectionChanged,
													onRowClicked,
													onCellClicked,
													onRowDoubleClicked,
													gridRef,
													gridOptions,
													gridWidth = '400px',
													gridHeight = '100%',
												}: GridProps<T>) {
	const defaultColDef = useMemo(() => {
		return {
			editable: false,
			flex: 1,
			// minWidth: 100,
			filter: true,
			autoHeight: true,
			floatingFilter: true
		};
	}, []);

	return (
		<>
			<div
				className="ag-theme-quartz ag-theme-acmecorp"
				style={{
					height: gridHeight,
					width: gridWidth,
				}}
			>
				<AgGridReact
					ref={gridRef}
					rowData={rowData}
					columnDefs={cols}
					defaultColDef={defaultColDef}
					rowSelection={rowSelection}
					onRowClicked={onRowClicked}
					suppressCellFocus={suppressCellFocus}
					rowMultiSelectWithClick={multiSelectWithClick}
					onRowDoubleClicked={onRowDoubleClicked}
					// suppressRowClickSelection={true}
					onCellClicked={onCellClicked}
					onSelectionChanged={onSelectionChanged}
					gridOptions={gridOptions}
				></AgGridReact>
			</div>
		</>
	);
};
