import {useDispatch} from 'react-redux'
import { Navigate } from "react-router-dom";
import * as auth from './redux/AuthRedux'

export function Logout() {
  const dispatch = useDispatch();
  dispatch(auth.actions.logout());

  return (
    <Navigate to="/auth/login" />
  );
}
