import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { SkeDialogDelete } from '../../../../common/components/dialog-delete';
import { SkeDialogTextPrompt } from '../../../../common/components/dialog-text-prompt';
import { RootState } from '../../../../setup';
import {
	createCoachingCategory,
	getCoachingCategories,
	removeCoachingCategory,
	updateCoachingCategory,
} from '../../../modules/CRUD/CRUD';
import './styles/coaching.scss';

interface CategoryType {
	id: number,
	name: string
}

export function CoachingEdit() {
	const { companyId } = useParams();
	invariant(companyId, 'Company id falsy in this route');
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const [coachingCategories, setCoachingCategories] = useState<CategoryType[]>([]);
	const [selectedCoachingCategory, setSelectedCoachingCategory] = useState<CategoryType>();
	const [showCategoryNameDialog, setShowCategoryNameDialog] = useState<boolean>(false);
	const [showDeleteCoachingCategory, setShowDeleteCoachingCategory] = useState<boolean>(false);
	const [categoryNameDialogType, setCategoryNameDialogType] = useState<'add' | 'edit'>();

	const refreshData = (): Promise<void> => {
		return getCoachingCategories(companyId, token)
			.then(({ data }) => {
				setCoachingCategories(data.items);
			})
			.catch(err => {
				console.error(err);
				toast.error('Error loading categories');
			});
	};

	const closeAndResetDialogs = () => {
		setShowCategoryNameDialog(false);
		setShowDeleteCoachingCategory(false);
		setSelectedCoachingCategory(undefined);
		setCategoryNameDialogType(undefined);
	};

	const handleAddCategory = (value: string) => {
		if (value !== '') {
			createCoachingCategory(companyId, value, token)
				.then(() => {
					refreshData();
					closeAndResetDialogs();
				})
				.catch(err => {
					console.log(err.response);
					toast.error('Error creating category');
				});
		}
	};

	const handleEditCategory = (value: string) => {
		invariant(selectedCoachingCategory, `Can't proceed with edit of category as it's undefined`);
		if (value !== '') {
			updateCoachingCategory(selectedCoachingCategory.id, value, token)
				.then(() => {
					refreshData();
					closeAndResetDialogs();
				})
				.catch(err => {
					console.log(err.response);
					toast.error('Error updating category');
				});
		}
	};

	const handleConfirmDelete = () => {
		invariant(selectedCoachingCategory, `Can't proceed with deletion of category as it's undefined`);
		removeCoachingCategory(selectedCoachingCategory.id, token)
			.then(() => {
				refreshData();
				closeAndResetDialogs();
			})
			.catch(err => {
				console.log(err.response);
				console.log('Error deleting category');
			});
	};

	useEffect(() => {
		refreshData();
	}, [companyId, setCoachingCategories, token]);

	return (
		<>
			<div className="card coaching_settings_container">
				<div
					className="card-header border-0 cursor-pointer"
					role="button"
				>
					<div className="card-title m-0">
						<h3 className="fw-bolder m-0">Coaching Categories</h3>
					</div>
				</div>
				<div className="card-body border-top">
					<div className="text-start">
						<button
							type="button"
							className="link-primary btn btn_add mt-2 px-0"
							onClick={() => {
								setCategoryNameDialogType('add');
								setShowCategoryNameDialog(true);
							}}>+ Add New
						</button>
					</div>
					<div className="mb-0 check_wrapper">
						{coachingCategories.length > 0 && (
							<div className="table-responsive">
								<table className="table table-striped">
									<thead>
										<tr>
											<th className="text-muted fw-bold min-w-300px">Name</th>
											<th className="text-muted fw-bold w-200px"></th>
										</tr>
									</thead>
									<tbody>
										{coachingCategories.map((category, index) => {
											return <tr
												className="action_line"
												key={index}>
												<td className="action_name">{category.name}</td>
												<td className="action_buttons">
												<span
													className="btn_remove"
													onClick={() => {
														setSelectedCoachingCategory(category);
														setCategoryNameDialogType('edit');
														setShowCategoryNameDialog(true);
													}}
												>
														<i className="fas fa-edit text-primary fs-4"></i>
												</span>
													<span
														className="btn_remove ms-3"
														onClick={() => {
															setSelectedCoachingCategory(category);
															setShowDeleteCoachingCategory(true);
														}}
													>
													<i className="fas fa-trash text-danger fs-4"></i>
												</span>
												</td>
											</tr>;
										})}
									</tbody>
								</table>
							</div>
						)}
					</div>

				</div>
			</div>
			{showCategoryNameDialog && (
				<SkeDialogTextPrompt
					title={`${categoryNameDialogType === 'add' ? 'New' : 'Edit'} Coaching Category`}
					initialValue={categoryNameDialogType === 'add' ? '' : selectedCoachingCategory?.name}
					onConfirm={(value: string) => {
						if (categoryNameDialogType === 'add') {
							return handleAddCategory(value);
						}
						return handleEditCategory(value);
					}}
					onCancel={closeAndResetDialogs}
				/>
			)}
			{showDeleteCoachingCategory && (
				<SkeDialogDelete
					onCancel={closeAndResetDialogs}
					onConfirm={handleConfirmDelete}
					successMessage="Category deleted"
					message="Are you sure you want to delete this category?"
				/>
			)}
		</>
	);
}
