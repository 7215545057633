/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import React, { FC } from 'react'
import { useLocation } from "react-router";
import { Navigate, Route, Routes } from "react-router-dom";
import { shallowEqual, useSelector } from 'react-redux'
import { PrivateRoutes } from './PrivateRoutes'
import { Logout, AuthPage } from '../modules/auth'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { RootState } from '../../setup'
import { UserModel } from '../models/UserModel'
import { MasterInit } from '../../_library/layout/MasterInit'
import { PublicRoutes } from "./PublicRoutes";

const MainRoutes: FC = () => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const isAuthorized = !!user?.roles?.includes('SecchiAdmin')
  const location = useLocation();

  return (
    <>
      <Routes>
        <Route path='/error/*' element={<ErrorsPage />} />
        <Route path='/logout' element={<Logout />} />
        {
          isAuthorized && <Route path='*' element={<PrivateRoutes />} />
        }
        {
          !isAuthorized && (
            <>
              <Route path='/auth/*' element={<AuthPage />} />
              {
                location.pathname !== '/auth/login'
                  ? <Route path='*' element={<Navigate to='/auth/login' />} />
                  : <Route path='*' element={<PublicRoutes />} />
              }
            </>)
        }
      </Routes>
      <MasterInit />
    </>
  )
}

export { MainRoutes }
