// import {toAbsoluteUrl} from '../../../helpers'

import logo from '../../../../images/logos/simple_logo.png'

export function FallbackView() {
  return (
    <div className='splash-screen'>
      <img src={logo} alt='Start logo' width={100} height={100} />
      <span>Loading ...</span>
    </div>
  )
}
