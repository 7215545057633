import { FC } from 'react'
import { PageTitle } from '../../../_library/layout/core'

const DashboardPage: FC = () => (
  <>
    No content built yet
  </>
)

const DashboardWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
