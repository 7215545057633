const industries = [
    "Accommodation and Food Services",
    "Administration, Business Support and Waste Management Services", 
    "Agriculture, Forestry, Fishing and Hunting", 
    "Arts, Entertainment and Recreation", 
    "Construction", 
    "Educational Services", 
    "Finance and Insurance", 
    "Healthcare and Social Assistance", 
    "Information", 
    "Manufacturing", 
    "Mining", 
    "Other Services (except Public Administration)", 
    "Professional, Scientific and Technical Services", 
    "Real Estate and Rental and Leasing", 
    "Retail Trade", 
    "Transportation and Warehousing", 
    "Utilities", 
    "Wholesale Trade"
]

export { industries }