import clsx from 'clsx';
import {useEffect, useMemo, useState} from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { KTSVG, toAbsoluteUrl } from '../../../_library/helpers';
import { useSortableData } from '../../../_library/helpers/sortTable';
import { PageTitle } from '../../../_library/layout/core';
import { SkeDialogDelete } from '../../../common/components/dialog-delete';
import { SkeDialogTextPrompt } from '../../../common/components/dialog-text-prompt';
import { RootState } from '../../../setup';
import { UserModel } from '../../models/UserModel';
import { deleteUser, getUsers, inviteSuperadmin } from '../../modules/CRUD/CRUD';
import { Grid } from "../../../common/components/grid";

type Props = {
	className?: string
}

function UsersPage({ className }: Props) {
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const [allUsers, setAllUsers] = useState<UserModel[]>([]);
	const {
		items,
		requestSort,
		sortConfig,
	} = useSortableData(allUsers);
	const [selectedUser, setSelectedUser] = useState<UserModel>();
	const [showInviteUserDialog, setShowInviteUserDialog] = useState<boolean>(false);
	const [showDeleteUserDialog, setShowDeleteUserDialog] = useState<boolean>(false);

	const refreshData = (): Promise<void> => {
		return getUsers(token)
			.then(({ data }) => {
				for (let i = 0; i < data.items.length; i++) {
					data.items[i].fullName = data.items[i].firstName + ' ' + data.items[i].lastName;
					data.items[i].companyName = data.items[i].company ? data.items[i].company.name : '';
				}
				setAllUsers(data.items);
			})
		.catch(err => {
			console.error(err);
			toast.error('Error loading users');
		})
	};

	const closeAndResetDialogs = () => {
		setSelectedUser(undefined);
		setShowInviteUserDialog(false);
		setShowDeleteUserDialog(false);
	};

	const handleInviteUser = (value: string) => {
		if (value !== '') {
			inviteSuperadmin(value, token)
				.then(() => {
					toast.success('SuperAdmin invited');
					refreshData();
					closeAndResetDialogs();
				})
				.catch(err => {
					console.error(err);
					toast.error('Error inviting superadmin');
				});
		}
	};

	const handleConfirmDelete = () => {
		invariant(selectedUser, `Can't proceed with deletion of user as it's undefined`);
		deleteUser(selectedUser.id, token)
			.then(() => {
				refreshData();
				closeAndResetDialogs();
			})
		.catch(err => {
			console.error(err);
			toast.error('Error deleting user');
		});
	};

	const getClassNamesFor = (name: string) => {
		if (!sortConfig) {
			return;
		}
		return sortConfig.key === name ? sortConfig.direction : undefined;
	};

	useEffect(() => {
		getUsers(token)
			.then(({ data }) => {
				for (let i = 0; i < data.items.length; i++) {
					data.items[i].fullName = data.items[i].firstName + ' ' + data.items[i].lastName;
					data.items[i].companyName = data.items[i].company ? data.items[i].company.name : '';
				}
				setAllUsers(data.items);

				// Set the ag-grid rowData
				setRowData(data.items);
			});
	}, [token]);

	const [rowData, setRowData] = useState<any>( [] );

	const [columnDefs, setColumnDefs] = useState<any>([
		{
			field: "id",
			headerName: "Make",
			width: 150,
			hide: true
		},
		{
			field: "firstName",
			headerName: "First Name",
			width: 150,
			hide: true
		},
		{
			field: "lastName",
			headerName: "Last Name",
			width: 150,
			hide: true
		},
		{
			field: "fullName",
			headerName: "User",
			width: 150,
			filter: 'agTextColumnFilter',
			cellRenderer: (params: any) => {
				return <>
					<div className="symbol symbol-45px me-5 symbol-circle">
						<img
							src={(!params.data.avatarUrl || params.data.avatarUrl?.includes('media/icons/duotune/general/create-team-icon')) ? toAbsoluteUrl('/media/avatars/blank.png') : params.data.avatarUrl}
							alt="avatar" />
					</div>
					{params.data.firstName!} {params.data.lastName!}
				</>
			},
			cellStyle: ( params: any ) => {
				return {
					'fontWeight': '700'
				}
			}
		},
		{
			field: "email",
			headerName: "Email",
			width: 150,
			cellStyle: ( params: any ) => {
				return {
					'fontWeight': '500'
				}
			}
		},
		{
			field: "isEmailVerified",
			headerName: "Email Verified",
			width: 150,
			valueGetter: (params: any) => {
				if( params.data.isEmailVerified ){
					return 'Verified';
				} else {
					return 'Unverified'
				}
			},
			cellStyle: ( params: any ) => {
				if( params.data.isEmailVerified ) {
					return {
						color: '#47BE7D',
						'fontWeight': 'bold'
					}
				} else{
					return {
						color: 'red',
						'fontWeight': 'bold'
					}
				}
			}
		},
		{
			field: "isLockedOut",
			headerName: "Locked Out",
			width: 150,
			valueGetter: (params: any) => {
				if( !params.data.isLockedOut ){
					return 'Unlocked';
				} else {
					return 'Locked'
				}
			},
			cellStyle: ( params: any ) => {
				if( !params.data.isLockedOut ) {
					return {
						color: '#47BE7D',
						'fontWeight': 'bold'
					}
				} else{
					return {
						color: 'red',
						'fontWeight': 'bold'
					}
				}
			}
		},
		{
			field: "roles",
			headerName: "Role",
			width: 150,
			cellRenderer: (params: any) => {
				return <>
					{(params.data.roles as string[]).map((role, i) => {
						return (
							<span
								key={i}
								className={clsx('badge me-2', {
									'badge-primary': role === 'SuperAdmin',
									'badge-info': role === 'SecchiAdmin',
									'badge-success': role === 'Supervisor',
									'badge-warning': role === 'Provisioner',
								})}>{role}</span>
						);
					})}
				</>
			},
		},
		{
			field: "companyName",
			headerName: "Company",
			width: 150,
		},
		{
			field: "actions",
			headerName: "Actions",
			suppressMenu: true,
			suppressSorting: true,
			suppressFilter: true,
			cellRenderer: (params: any) => {
				return <>
					<div className="text-end">
						<Link
							to={`/edit-user/${params.data.id}`}
							className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
						>
							<KTSVG
								path="/media/icons/duotune/general/gen055.svg"
								className="svg-icon-3"
							/>
						</Link>
						<Link
							to="#"
							className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm ms-2"
							onClick={() => {
								setSelectedUser(params.data);
								setShowDeleteUserDialog(true);
							}}
						>
							<KTSVG
								path="/media/icons/duotune/general/gen027.svg"
								className="svg-icon-3"
							/>
						</Link>
					</div>
				</>
			},
		}
	]);

	return (
		<>
			<PageTitle breadcrumbs={[]}>Users</PageTitle>
			<div className={`card ${className}`}>
				<div className="card-header border-0 pt-5">
					<div className="pt-7 d-flex w-200px align-items-center"></div>
					<div
						className="card-toolbar"
						data-bs-toggle="tooltip"
						data-bs-placement="top"
						data-bs-trigger="hover"
						title="Click to add a user"
					>
						<div className="d-flex align-items-center py-1">
							<div className="me-4">
								<Link
									to="#"
									className="btn btn-sm btn-light-primary"
									onClick={() => {
										setShowInviteUserDialog(true);
									}}
								>
									Invite SuperAdmin
								</Link>
							</div>
							<div className="me-4">
								<Link
									to="/create-user"
									className="btn btn-sm btn-light-primary"
									data-kt-menu-trigger="click"
								>
									<i className="bi bi-plus svg-icon-5 svg-icon-gray-500 me-1"></i>
									Create
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="card-body py-3">
					{/*Grid component*/}
					<Grid
						rowData={rowData}
						cols={columnDefs}
						gridHeight={'760px'}
						gridWidth={'100%'}
					></Grid>
				</div>
			</div>
			{showInviteUserDialog && (
				<SkeDialogTextPrompt
					title='Invite SuperAdmin'
					message='Enter an email to invite user'
					initialValue=''
					onConfirm={handleInviteUser}
					onCancel={closeAndResetDialogs}
				/>
			)}
			{showDeleteUserDialog && (
				<SkeDialogDelete
					onCancel={closeAndResetDialogs}
					onConfirm={handleConfirmDelete}
					successMessage="User deleted"
					message="Are you sure you want to delete this user?"
				/>
			)}
		</>
	);
}

export default UsersPage;
