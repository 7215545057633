import React, { ReactNode, useEffect } from "react";
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {useLayout} from '../core'
import {DrawerComponent} from '../../assets/ts/components'

interface ContentProps {
  children?: ReactNode;
}

export function Content ({children}: ContentProps) {
  const {classes} = useLayout()
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  return (
    <div id='kt_content_container' className={clsx(classes.contentContainer.join(' ')) + ' mw-100'}>
      {children}
    </div>
  )
}
