import { Company } from '../../company/models/company.model';
import { TemplateModel } from '../../template/models/template.model';

export interface CategoryModel {
	id: number;
	tenant_id: string;
	// TODO: CompanyGroup/ABAC
	group_id: null;
	type: CategoryTypeEnum;
	name: string;
	// not yet implemented
	metadata: unknown;
	subtype: CategorySubtypeEnum | null;
	company: Company;
	updated_at: string;
	created_at: string;
	deleted_at: string | null;
	templates: TemplateModel[];
	settings: unknown;
	parent_id: number;
	parent?: CategoryModel;
}

export enum CategoryTypeEnum {
	Discipline = 'DISCIPLINE',
	Coaching = 'COACHING',
	Attendance = 'ATTENDANCE',
	Recognition = 'RECOGNITION',
}

export enum CategorySubtypeEnum {
	// for CategoryTypeEnum.Discipline
	Attendance = 'ATTENDANCE',
	// for CategoryTypeEnum.Discipline
	Performance = 'PERFORMANCE',
}
