import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LayoutProvider, LayoutSplashScreen } from '../_library/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import { MainRoutes } from './routing/MainRoutes'

type Props = {
  basename: string
}

const App: React.FC<Props> = ({ basename }) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <LayoutProvider>
          <ToastContainer />
          <AuthInit>
            <MainRoutes />
          </AuthInit>
        </LayoutProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export { App }
