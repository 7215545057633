import React, { ReactNode, useEffect } from "react";
import {AsideDefault} from './components/aside/AsideDefault'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from '../assets/ts/components'

interface MasterLayoutProps {
  children?: ReactNode;
}

function MasterLayout({ children }: MasterLayoutProps) {
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  return (
    <PageDataProvider>
      <div className="page d-flex flex-row flex-column-fluid">
        <AsideDefault />
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper">
          <HeaderWrapper />

          <div
            id="kt_content"
            className="content d-flex flex-column flex-column-fluid">
            <Toolbar />
            <div
              className="post d-flex flex-column-fluid"
              id="kt_post">
              <Content>{children}</Content>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
      <ScrollTop />
    </PageDataProvider>
  );
}

export {MasterLayout}
