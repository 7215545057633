import { IRowNode, SelectionChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { AxiosResponse } from 'axios';
import clsx from 'clsx';
import { compact, map } from 'lodash';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { shallowEqual, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { KTSVG } from '../../../_library/helpers';
import { PageTitle } from '../../../_library/layout/core';
import { SkeDialogDelete } from '../../../common/components/dialog-delete';
import { Grid } from '../../../common/components/grid';
import { CategoryModel, CategorySubtypeEnum, CategoryTypeEnum } from '../../../features/category/models/category.model';
import { Company } from '../../../features/company/models/company.model';
import {
	TemplateData,
	TemplateModel,
	TemplateReplicationWithExistingCategory,
	TemplateReplicationWithNewCategory,
	TemplateTypeEnum,
} from '../../../features/template/models/template.model';
import { RootState } from '../../../setup';
import {
	deleteTemplate,
	getCategories,
	GetCategoriesOpts,
	getCompanies,
	replicateTemplates,
} from '../../modules/CRUD/CRUD';
import { EditTemplate } from './EditTemplate';

type Props = {
	className?: string;
};

export interface CategoryTemplateFlattened {
	companyName: string;
	tenant_id: string;

	categoryId: number;
	categoryName: string;
	categoryType: CategoryTypeEnum;
	categorySubtype: CategorySubtypeEnum | null;

	templateId: number;
	templateName: string;
	templateDescription: string | null;
	templateType: TemplateTypeEnum;
	template: TemplateData;
}

export function TemplatesPage({className}: Props) {
	const token: string = useSelector<RootState>(
		({auth}) => auth.accessToken,
		shallowEqual,
	) as string;
	const [flattenedCategories, setFlattenedCategories] = useState<CategoryTemplateFlattened[]>([]);
	const [selectedTemplate, setSelectedTemplate] = useState<CategoryTemplateFlattened>();
	const [selectedTemplateIds, setSelectedTemplateIds] = useState<number[]>([]);
	const [selectedFlatTemplates, setSelectedFlatTemplates] = useState<CategoryTemplateFlattened[]>([]);
	const [showDeleteTemplateDialog, setShowDeleteTemplateDialog] = useState<boolean>(false);
	const [companies, setCompanies] = useState<Company[]>([]);
	const [selectedCompany, setSelectedCompany] = useState<Company>();
	const [selectedCategory, setSelectedCategory] = useState<CategoryModel>();
	// name if creating category
	const [newCategoryName, setNewCategoryName] = useState<string>('');
	const [categoriesForSelectedTenant, setCategoriesForSelectedTenant] = useState<CategoryModel[]>([]);
	const [showTemplateEditor, setShowTemplateEditor] = useState<boolean>(false);
	const [templateEditorMode, setShowTemplateEditorMode] = useState<'CREATE' | 'UPDATE'>('CREATE');
	const gridRef = useRef<AgGridReact>(null);
	const [useExistingCategory, setUseExistingCategory] = useState<boolean>(true);
	const [createBlankTemplate, setCreateBlankTemplate] = useState<boolean>(false);
	const [newCategorySubtype, setNewCategorySubtype] = useState<CategorySubtypeEnum>(CategorySubtypeEnum.Performance);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const fetchCategories = async (opts?: GetCategoriesOpts): Promise<CategoryModel[]> => {
		return getCategories(token, opts)
			.then(({data}) => data)
			.catch((err) => {
				console.error(err);
				toast.error('Error loading categories');
				return [];
			});
	};

	const flattenCategories = (data: CategoryModel[]): CategoryTemplateFlattened[] => {
		const transformedData: CategoryTemplateFlattened[] = [];
		data.map((category: CategoryModel) => {
			category.templates.forEach((template: TemplateModel) => {
				transformedData.push({
					//TODO: company not null and will always be available
					companyName: category.company!.name,
					tenant_id: category.tenant_id,
					categoryId: category.id,
					categoryName: category.name,
					categoryType: category.type,
					categorySubtype: category.subtype,
					templateId: template.id,
					templateName: template.name,
					templateDescription: template.description,
					templateType: template.type,
					template: template.template,
				});
			});
		});
		return transformedData;
	};

	const refreshData = async (): Promise<void> => {
		const cats = await fetchCategories({type: [CategoryTypeEnum.Discipline]});
		setFlattenedCategories(flattenCategories(cats));
	};

	const resetPage = () => {
		setFlattenedCategories([]);
		setSelectedTemplate(undefined);
		setSelectedTemplateIds([]);
		setSelectedFlatTemplates([]);
		setShowDeleteTemplateDialog(false);
		setSelectedCompany(undefined);
		setSelectedCategory(undefined);
		setNewCategoryName('');
		setCategoriesForSelectedTenant([]);
		setShowTemplateEditor(false);
		setShowTemplateEditorMode('CREATE');
		setUseExistingCategory(true);
		setNewCategorySubtype(CategorySubtypeEnum.Performance);
		setIsSubmitting(false);
		refreshData();
	};

	const loadCompanies = async () => {
		return getCompanies(token)
			.then(({data}) => {
				setCompanies(data.items);
			})
			.catch((error) => console.error('Failed to fetch companies:', error));
	};

	useEffect(() => {
		// need ag-grid enterprise to not have a button within a cell trigger the row click event
		if (!!selectedTemplate) {
			gridRef.current?.api.deselectAll();
		}
	}, [selectedTemplate]);

	useEffect(() => {
		if (selectedFlatTemplates.length) {
			setSelectedTemplate(undefined);
			setShowTemplateEditor(false);
		}
	}, [selectedFlatTemplates]);

	useEffect(() => {
		refreshData();
		loadCompanies();
	}, [token]);

	const onSelectionChanged = (event: SelectionChangedEvent<CategoryTemplateFlattened>) => {
		const selectedNodes: IRowNode<CategoryTemplateFlattened>[] = event.api.getSelectedNodes();
		const tmpl: CategoryTemplateFlattened[] = compact(selectedNodes.map((node) => node.data));
		setSelectedFlatTemplates(tmpl);
		setSelectedTemplateIds(map(tmpl, 'templateId'));
	};

	const clearCopyTemplateParts = (scope: 'company' | 'category') => {
		// if any of these change, always clear category stuff
		setNewCategoryName('');
		setCreateBlankTemplate(false);
		setSelectedCategory(undefined);
		setNewCategorySubtype(CategorySubtypeEnum.Performance);

		if (scope === 'company') {
			setUseExistingCategory(true);
			setSelectedCompany(undefined);
		}
	};

	//Note: step 2 this function needs to connect to function above. After a user selects a template, this function will select which company tenant_id to duplicate the template to.
	const handleCompanySelect = async (event: ChangeEvent<HTMLSelectElement>) => {
		clearCopyTemplateParts('company');
		const company = companies.find(cmp => cmp.id === event.target.value);
		if (!company) {
			throw new Error('Unable to find company selected');
		}
		setSelectedCompany(company);
		const cats = await fetchCategories({
			type: [CategoryTypeEnum.Discipline],
			tenant_id: company.id,
		});
		setCategoriesForSelectedTenant(cats);
		if (cats.length === 0) {
			if (selectedFlatTemplates.length) {
				setUseExistingCategory(false);
				setNewCategoryName(selectedFlatTemplates[0].categoryName);
				setNewCategorySubtype(selectedFlatTemplates[0].categorySubtype || CategorySubtypeEnum.Performance);
				setCreateBlankTemplate(true);
			}
		}
	};

	const handleSelectExistingCategory = (event: ChangeEvent<HTMLSelectElement>) => {
		const newCat = categoriesForSelectedTenant.find(cat => cat.id === +event.target.value);
		if (!newCat) {
			throw new Error('Unable to find category selected');
		}
		setSelectedCategory(newCat);
	};

	const handleReplicateTemplates = () => {
		if (!selectedCompany) {
			return console.error(`Need a company selected to copy templates to`);
		}
		setIsSubmitting(true);
		let asyncCall: Promise<AxiosResponse<string>>;

		if (useExistingCategory) {
			if(!selectedCategory) {
				return console.error('Need category selected to replicate to');
			}
			const saveData: TemplateReplicationWithExistingCategory = {
				tenant_id: selectedCompany.id,
				templateIds: selectedFlatTemplates.map(itm => itm.templateId),
				createBlankTemplate: createBlankTemplate,
				category_id: selectedCategory.id,
			};
			asyncCall = replicateTemplates(saveData, token);
		} else {
			const saveData: TemplateReplicationWithNewCategory = {
				tenant_id: selectedCompany.id,
				templateIds: selectedFlatTemplates.map(itm => itm.templateId),
				createBlankTemplate: createBlankTemplate,
				category_name: newCategoryName,
				category_subtype: newCategorySubtype,
			};
			asyncCall = replicateTemplates(saveData, token);
		}

		asyncCall
			.then((res) => {
				toast.success(res.data);
				resetPage();
			})
			.catch(err => {
				toast.error('Error replicating templates');
				setIsSubmitting(false);
			});
	};

	const closeAndResetDialogs = () => {
		setSelectedTemplate(undefined);
		setShowDeleteTemplateDialog(false);
	};

	const handleConfirmDelete = () => {
		if (!selectedTemplate) {
			return console.error(`Unable to delete template as selectedTemplate is falsy`);
		}
		deleteTemplate(selectedTemplate.templateId, token)
			.then(() => {
				closeAndResetDialogs();
				resetPage();
			})
			.catch((err) => {
				console.error(err);
				toast.error('Error deleting template');
			});
	};

	const [columnsDef, setColumnDefs] = useState<any>([
		{
			// field: '',
			headerName: 'Copy',
			// width: 25,
			checkboxSelection: true,
		},
		{
			field: 'companyName',
			headerName: 'Company',
			width: 150,
			filter: 'agTextColumnFilter',
		},
		{
			field: 'categoryType',
			headerName: 'Category Type',
			width: 150,
			filter: 'agTextColumnFilter',
		},
		{
			field: 'categoryName',
			headerName: 'Category Name',
			width: 150,
			filter: 'agTextColumnFilter',
		},
		{
			field: 'templateName',
			headerName: 'Template Name',
			width: 150,
			filter: 'agTextColumnFilter',
			tooltipComponent: 'customTooltip',
		},
		{
			field: 'templateDescription',
			headerName: 'Template Description',
			width: 150,
			filter: 'agTextColumnFilter',
		},
		{
			field: 'actions',
			headerName: 'Actions',
			suppressMenu: true,
			sortable: false,
			filter: false,
			// checkboxSelection: false,
			cellRenderer: (params: any) => {
				return (
					<>
						<div
							className="text-end"
							onClick={e => {
								e.preventDefault();
								e.stopPropagation();
							}}
						>
							<button
								className='btn btn-icon btn-outline-primary btn-sm'
								disabled={selectedTemplateIds.length > 0}
								onClick={(event) => {
									event.preventDefault();
									event.stopPropagation();
									setShowTemplateEditorMode('UPDATE');
									setSelectedTemplate(params.data);
									setShowTemplateEditor(true);
								}}
							>
								<KTSVG
									path="/media/icons/duotune/general/gen055.svg"
									className="svg-icon-3" />
							</button>
							<button
								className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm ms-2"
								onClick={(e) => {
									e.stopPropagation();
									e.preventDefault();
									setSelectedTemplate(params.data);
									setShowDeleteTemplateDialog(true);
								}}
							>
								<KTSVG
									path="/media/icons/duotune/general/gen027.svg"
									className="svg-icon-3" />
							</button>
						</div>
					</>
				);
			},
		},
	]);

	return (
		<>
			<PageTitle breadcrumbs={[]}>Templates</PageTitle>
			<div className={`card ${className}`}>
				<div className="card-header border-0 pt-5">
					<div>
						<h1>
							Template Manager
							<button
								className="btn btn-sm btn-out-line-primary btn-link ms-3"
								onClick={() => {
									setShowTemplateEditorMode('CREATE');
									setShowTemplateEditor(true);
								}}
							>
								<i className="bi bi-plus svg-icon-5 text-primary"></i>
								Create
							</button>
						</h1>
						<span>
							Click row so checkbox is selected to copy to another instance, or click pencil/trash to edit or delete this template for this instance.
						</span>
					</div>
				</div>
				<div className="card-body py-3">

					<Grid<CategoryTemplateFlattened>
						rowData={flattenedCategories}
						cols={columnsDef}
						rowSelection="multiple"
						onSelectionChanged={onSelectionChanged}
						suppressCellFocus={true}
						multiSelectWithClick={false}
						gridRef={gridRef}
						gridHeight={'400px'}
						gridWidth={'100%'}
					/>
				</div>
				{selectedTemplateIds.length > 0 && showTemplateEditor && (
					<>
						<span className="fs-3">
							Cannot edit template while also selecting templates to copy
							<button
								onClick={() => {
									setSelectedTemplateIds([]);
									setSelectedFlatTemplates([]);
									closeAndResetDialogs();
									gridRef.current?.api.deselectAll();
								}}
								className="d-inline-block btn btn-outline-danger">
								Reset
							</button>
						</span>
					</>
				)}
				{selectedTemplateIds.length > 0 && !showTemplateEditor && (
					<div className="p-10 d-flex">
						<div className="col-12 col-sm-6 col-md-3">
							<h3>Selected Templates</h3>
							<ul className="list-unstyled">
								{selectedFlatTemplates?.map((tmpl, idx) => (
									<li
										key={tmpl.templateId}
										className="mb-2 bg-hover-secondary">
									<span className="fw-bold d-block fs-4 position-relative">
										{tmpl.templateName}
										<button
											className="btn btn-sm btn-outline-danger end-0 top-0 position-absolute"
											onClick={() => {
												const selected = gridRef.current?.api.getSelectedNodes();
												if (selected?.length) {
													const toDeselect = selected?.filter(nd => nd.data?.templateId === tmpl.templateId);
													gridRef.current?.api.setNodesSelected({
														nodes: toDeselect,
														newValue: false,
													});
												}
											}}
										>X
										</button>
									</span>
										<small className="d-block fs-5 fw-light">From: {tmpl.companyName} &gt; {tmpl.categoryName}
										</small>
									</li>
								))}
							</ul>
						</div>
						<div className="col-12 col-sm-6 col-md-3">
							<h3>Destination Instance</h3>
							<Form.Select
								defaultValue=""
								onChange={handleCompanySelect}
								value={selectedCompany?.id}>
								<option
									disabled={true}
									value="">
									Select an instance
								</option>
								{companies?.map(cmp => (
									<option
										key={cmp.id}
										value={cmp.id}>
										{cmp.name}
									</option>
								))
								}
							</Form.Select>
						</div>
						<div
							className={clsx('col-12 col-sm-6 col-md-3', {
								'invisible': !selectedCompany,
							})}>
							<h3>Destination Category</h3>
							<Form.Switch
								checked={useExistingCategory}
								onChange={(event) => {
									clearCopyTemplateParts('category');
									setUseExistingCategory(event.target.checked);
								}}
								label={useExistingCategory ? 'Existing Category' : 'New Category'}
							/>
							{useExistingCategory && (
								<>
									<Form.Select
										defaultValue=""
										value={selectedCategory?.id}
										onChange={handleSelectExistingCategory}
									>
										<option
											disabled={true}
											value="">
											{categoriesForSelectedTenant.length === 0 ? 'None - Create new' : 'Select Category'}
										</option>
										{categoriesForSelectedTenant?.map(cat => (
											<option
												value={cat.id}>
												{cat.name}
											</option>
										))
										}
									</Form.Select>
								</>
							)
							}
							{!useExistingCategory && (
								<>
									<Form.Control
										value={newCategoryName}
										onChange={e => setNewCategoryName(e.target.value)}
									>
									</Form.Control>
									<Form.Check
										type="radio"
										value={CategorySubtypeEnum.Performance}
										name="new-cat-subtype"
										checked={newCategorySubtype === CategorySubtypeEnum.Performance}
										onChange={() => setNewCategorySubtype(CategorySubtypeEnum.Performance)}
										label={CategorySubtypeEnum.Performance}
										id="new-cat-perf"
									/>
									<Form.Check
										type="radio"
										value={CategorySubtypeEnum.Attendance}
										name="new-cat-subtype"
										checked={newCategorySubtype === CategorySubtypeEnum.Attendance}
										onChange={() => setNewCategorySubtype(CategorySubtypeEnum.Attendance)}
										label={CategorySubtypeEnum.Attendance}
										id="new-cat-att"
									/>
									<Form.Check
										checked={createBlankTemplate}
										onChange={e => setCreateBlankTemplate(e.target.checked)}
										label='Add "Custom" (blank) template'
										id="create-blank-template"
									/>
								</>
							)
							}
						</div>

						<div
							className={clsx('col-12 col-sm-6 col-md-3', {
								'invisible': !selectedCompany || !(selectedCategory || newCategoryName),
							})}>
							<button
								disabled={selectedFlatTemplates.length === 0 || !selectedCompany || !(selectedCategory || newCategoryName) || isSubmitting}
								className="btn btn-primary"
								onClick={handleReplicateTemplates}
							>Replicate
							</button>
						</div>
					</div>
				)}
				{selectedTemplateIds.length === 0 && showTemplateEditor && (
					<EditTemplate
						flatTemplate={selectedTemplate}
						onSave={() => {
							refreshData();
							setSelectedTemplate(undefined);
						}}
						mode={templateEditorMode}
					/>
				)}
			</div>
			{showDeleteTemplateDialog && (
				<SkeDialogDelete
					onCancel={closeAndResetDialogs}
					onConfirm={handleConfirmDelete}
					successMessage="Template deleted"
					message="Are you sure you want to delete this template?"
				/>
			)}
		</>
	);
}
