import clsx from 'clsx';
import { useFormik } from 'formik';
import { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { toAbsoluteUrl } from '../../../_library/helpers';
import { PageLink, PageTitle } from '../../../_library/layout/core';
import { RootState } from '../../../setup';
import { createUser, setUserAvatar } from '../../modules/CRUD/CRUD';
import './styles/create_user.scss';

const accountBreadCrumbs: Array<PageLink> = [
	{
		title: 'Users',
		path: '',
		isSeparator: false,
		isActive: false,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
];

function CreateUser() {
	const navigate = useNavigate();
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const [loading, setLoading] = useState(false);
	const [avatarFile, setAvatarFile] = useState<any>(null);
	const [avatar, setAvatar] = useState('');
	const [isShowPwd, setIsShowPwd] = useState(false);

	const initialValues = {
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		changepassword: '',
		role: 'SecchiAdmin',
	};

	const registrationSchema = Yup.object().shape({
		firstName: Yup.string()
									.min(3, 'Minimum 3 symbols')
									.max(50, 'Maximum 50 symbols')
									.required('First name is required'),
		email: Yup.string()
							.email('Wrong email format')
							.min(3, 'Minimum 3 symbols')
							.max(50, 'Maximum 50 symbols')
							.required('Email is required')
							.matches(/^(?!.*(@gmail.com|@hotmail.com|@outlook.com|@yahoo.com)).*$/, 'Not Business Email'),
		lastName: Yup.string()
								 .min(3, 'Minimum 3 symbols')
								 .max(50, 'Maximum 50 symbols')
								 .required('Last name is required'),
		password: Yup.string()
								 .min(12, 'Minimum 12 symbols')
								 .max(50, 'Maximum 50 symbols')
								 .required('Password is required')
								 .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})/, 'Must include capital letter, special character and number'),
		changepassword: Yup.string()
											 .required('Password confirmation is required')
											 .when('password', {
												 is: (val: string) => (val && val.length > 0 ? true : false),
												 then: Yup.string().oneOf([Yup.ref('password')], 'Password and Confirm Password didn\'t match'),
											 }),
		role: Yup.string()
						 .required('Role is required'),
	});

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files[0]) {
			let reader = new FileReader();
			let file = e.target.files[0];
			setAvatarFile(file);
			reader.onloadend = () => {
				if (reader && reader.result) {
					setAvatar(reader.result as string);
				}
			};
			reader.readAsDataURL(file);
		}
	};

	const removeAvatar = () => {
		setAvatar('');
	};

	const formik = useFormik({
		initialValues,
		validationSchema: registrationSchema,
		onSubmit: (values,
							 {
								 setStatus,
								 setSubmitting,
							 }) => {
			setLoading(true);
			setTimeout(() => {
				createUser(
					values.email,
					values.firstName,
					values.lastName,
					values.password,
					values.changepassword,
					values.role.split(','),
					token,
				)
					.then(({ data }) => {
						if (avatarFile) {
							const requestBody = new FormData();
							requestBody.append('file', avatarFile);
							setUserAvatar(data.id, requestBody, token)
								.then(() => {
									setLoading(false);
									navigate('/users');
								})
								.catch(error => {
									console.log(error);
									setLoading(false);
									navigate('/users');
								});
						} else {
							setLoading(false);
							navigate('/users');
						}
					})
					.catch((err) => {
						console.log(err);
						toast.error(err.response.data.message || 'Error creating user');
						setLoading(false);
					});
			}, 1000);
		},
	});

	return (
		<>
			<PageTitle breadcrumbs={accountBreadCrumbs}>Create a User</PageTitle>
			<div className="card create_user_card">
				<div className="card-header border-0 pt-5"></div>
				<div className="card-body py-3">
					<div className="col-lg-12 text-center">
						<div className="d-flex align-items-center justify-content-center rounded bg-white bg-body shadow w-150px h-150px m-auto">
							<div
								className={`image-input image-input-outline w-120px h-120px ${avatar === '' ? 'image-input-empty' : ''}`}
								data-kt-image-input="true"
								style={{ backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})` }}
							>
								<div
									className="image-input-wrapper no-shadow shadow-none"
									style={{ backgroundImage: `url(${toAbsoluteUrl(avatar)})` }}
								></div>
								<label
									className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
									data-kt-image-input-action="change"
									data-bs-toggle="tooltip"
									title=""
									data-bs-original-title="Change avatar">
									<i className="bi bi-pencil-fill fs-7"></i>
									<input
										type="file"
										name="avatar"
										accept=".png, .jpg, .jpeg"
										onChange={handleFileChange} />
									<input
										type="hidden"
										name="avatar_remove" />
								</label>
								<span
									className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
									data-kt-image-input-action="remove"
									data-bs-toggle="tooltip"
									title=""
									data-bs-original-title="Remove avatar"
									onClick={removeAvatar}
								>
									<i className="bi bi-x fs-2"></i>
								</span>
							</div>
						</div>
						<div className="form-text mt-5">Set the product thumbnail image. Only
																						*.png, *.jpg and *.jpeg image files
																						are accepted
						</div>
					</div>
					<div className="w-lg-700px p-lg-15 p-10 bg-white rounded mx-auto">
						<form
							className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
							noValidate
							id="kt_login_signup_form"
							onSubmit={formik.handleSubmit}
						>
							<div className="row fv-row mb-7">
								<div className="col-xl-6">
									<div className="fv-row">
										<label className="form-label fw-bolder text-dark fs-6">First Name</label>
										<input
											placeholder="First Name"
											type="text"
											autoComplete="off"
											{...formik.getFieldProps('firstName')}
											className={clsx(
												'form-control form-control-lg form-control-solid',
												{
													'is-invalid': formik.touched.firstName && formik.errors.firstName,
												},
												{
													'is-valid': formik.touched.firstName && !formik.errors.firstName,
												},
											)}
										/>
										{formik.touched.firstName && formik.errors.firstName && (
											<div className="fv-plugins-message-container">
												<div className="fv-help-block">
													<span role="alert">{formik.errors.firstName}</span>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className="col-xl-6">
									<div className="fv-row">
										<label className="form-label fw-bolder text-dark fs-6">Last Name</label>
										<input
											placeholder="Last Name"
											type="text"
											autoComplete="off"
											{...formik.getFieldProps('lastName')}
											className={clsx(
												'form-control form-control-lg form-control-solid',
												{
													'is-invalid': formik.touched.lastName && formik.errors.lastName,
												},
												{
													'is-valid': formik.touched.lastName && !formik.errors.lastName,
												},
											)}
										/>
										{formik.touched.lastName && formik.errors.lastName && (
											<div className="fv-plugins-message-container">
												<div className="fv-help-block">
													<span role="alert">{formik.errors.lastName}</span>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>

							<div className="fv-row mb-7">
								<label className="form-label fw-bolder text-dark fs-6">Email</label>
								<input
									placeholder="Email"
									type="email"
									autoComplete="off"
									{...formik.getFieldProps('email')}
									className={clsx(
										'form-control form-control-lg form-control-solid',
										{ 'is-invalid': formik.touched.email && formik.errors.email },
										{
											'is-valid': formik.touched.email && !formik.errors.email,
										},
									)}
								/>
								{formik.touched.email && formik.errors.email && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">
											<span role="alert">{formik.errors.email}</span>
										</div>
									</div>
								)}
							</div>

							<div
								className="mb-10 fv-row"
								data-kt-password-meter="true">
								<div className="mb-1">
									<label className="form-label fw-bolder text-dark fs-6">Password</label>
									<div className="position-relative mb-3">
										<input
											type={isShowPwd ? 'text' : 'password'}
											placeholder="Password"
											autoComplete="off"
											{...formik.getFieldProps('password')}
											className={clsx(
												'form-control form-control-lg form-control-solid',
												{
													'is-invalid': formik.touched.password && formik.errors.password,
												},
												{
													'is-valid': formik.touched.password && !formik.errors.password,
												},
											)}
										/>
										{formik.touched.password && formik.errors.password && (
											<div className="fv-plugins-message-container">
												<div className="fv-help-block">
													<span role="alert">{formik.errors.password}</span>
												</div>
											</div>
										)}
										<button
											type="button"
											className="position-absolute btn p-0 btn_show_password"
											onClick={() => setIsShowPwd(!isShowPwd)}
										>
											{
												isShowPwd ?
													<i className="bi bi-eye-slash-fill fs-3"></i> :
													<i className="bi bi-eye-fill fs-3"></i>
											}
										</button>
									</div>
								</div>
							</div>

							<div className="fv-row mb-5">
								<label className="form-label fw-bolder text-dark fs-6">Confirm Password</label>
								<input
									type="password"
									placeholder="Password confirmation"
									autoComplete="off"
									{...formik.getFieldProps('changepassword')}
									className={clsx(
										'form-control form-control-lg form-control-solid',
										{
											'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
										},
										{
											'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
										},
									)}
								/>
								{formik.touched.changepassword && formik.errors.changepassword && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">
											<span role="alert">{formik.errors.changepassword}</span>
										</div>
									</div>
								)}
							</div>

							<div className="fv-row mb-7">
								<label className="form-label fw-bolder text-dark fs-6">Role</label>
								<select
									{...formik.getFieldProps('role')}
									className={clsx(
										'form-control form-control-lg form-control-solid',
										// {
										//     'is-invalid': formik.touched.role && formik.errors.role,
										// },
										// {
										//     'is-valid': formik.touched.role && !formik.errors.role,
										// }
									)}
									style={{ appearance: 'auto' }}
								>
									<option value=""></option>
									<option value="SecchiAdmin">SecchiAdmin</option>
									<option value="SuperAdmin">SuperAdmin</option>
									<option value="Provisioner">Provisioner</option>
									<option value="SuperAdmin,Provisioner">SuperAdmin & Provisioner</option>
								</select>
								{formik.touched.role && formik.errors.role && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">
											<span role="alert">{formik.errors.role}</span>
										</div>
									</div>
								)}
							</div>

							<div className="text-center mt-10">
								<button
									type="submit"
									id="kt_sign_up_submit"
									className="btn btn-lg btn-primary min-w-150px mb-5"
									disabled={formik.isSubmitting || !formik.isValid}
								>
									{!loading && <span className="indicator-label">Create</span>}
									{loading && (
										<span
											className="indicator-progress"
											style={{ display: 'block' }}
										>
											Please wait...{' '}
											<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
										</span>
									)}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}

export { CreateUser };
