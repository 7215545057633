import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {

  return (
    <>
      <AsideMenuItem
        to='/users'
        icon='/media/icons/duotune/communication/com006.svg'
        title="Users"
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/companies'
        icon='/media/icons/duotune/art/art002.svg'
        title="Companies"
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/templates'
        icon='/media/icons/duotune/art/art002.svg'
        title='Templates'
        fontIcon='bi-app-indicator'
      />
    </>
  )
}
