import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { ref } from 'yup';
import { SkeDialogDelete } from '../../../../../common/components/dialog-delete';
import { RootState } from '../../../../../setup';
import { getAttendanceAutomations, removeAttendanceAutomation } from '../../../../modules/CRUD/CRUD';
import { AttendanceAutomationType } from '../../../../types/AttendanceAutomationType';

type Props = {
	tenantId: string
	setShowPage: (page: string) => void
	setEditAutomation: (automations: AttendanceAutomationType) => void
}

export default function AttendanceAutomationsList({
																										tenantId,
																										setShowPage,
																										setEditAutomation,
																									}: Props) {
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const [automations, setAutomations] = useState<AttendanceAutomationType[]>([]);
	const [selectedAutomation, setSelectedAutomation] = useState<AttendanceAutomationType>();
	const [showDeleteAutomationDialog, setShowDeleteAutomationDialog] = useState<boolean>(false);

	const refreshData = (): Promise<void> => {
		return getAttendanceAutomations(tenantId, false, token)
			.then(({ data }) => {
				setAutomations(data.items);
			})
			.catch(err => {
				console.error(err);
				toast.error('Error loading automations');
			});
	};

	const closeAndResetDialogs = () => {
		setShowDeleteAutomationDialog(false);
		setSelectedAutomation(undefined);
	};

	const handleEditAutomation = (automation: AttendanceAutomationType) => {
		setEditAutomation(automation);
		setShowPage('edit');
	};

	const handleConfirmDelete = () => {
		invariant(selectedAutomation, `Can't proceed with deletion of automation as it's undefined`);
		removeAttendanceAutomation(selectedAutomation.id, token)
			.then(() => {
				refreshData();
				closeAndResetDialogs();
			})
			.catch(err => {
				console.error(err);
				toast.error('Error deleting automation');
			});
	};

	useEffect(() => {
		let abortController = new AbortController();

		if (!!tenantId) {
			refreshData();
		}

		return () => {
			abortController.abort();
		};
	}, [tenantId, token]);

	return (
		<>
			<div>
				<div className="d-flex">
					<button
						type="button"
						className="link-primary btn btn_add mt-2 px-0"
						onClick={() => setShowPage('create')}
					>
						+ Add New Attendance Automation
					</button>
				</div>
				<table className="table table-striped">
					<thead>
						<tr>
							<th className="fw-bolder">Name</th>
							<th className="fw-bolder">Description</th>
							<th className="fw-bolder">Trigger Points</th>
							<th className="fw-bolder">Discipline Step</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{automations.map((automation, index) => {
							return <tr
								className="action_line"
								key={index}>
								<td className="action_name">{automation.name}</td>
								<td className="action_name">{automation.description}</td>
								<td className="action_name">{automation.triggerPoints}</td>
								<td className="action_name">{automation.disciplineStep?.name}</td>
								<td className="action_buttons">
								<span
									className="btn_edit ms-3"
									onClick={() => handleEditAutomation(automation)}
								>
										<i className="fas fa-edit text-primary fs-4"></i>
								</span>
									<span
										className="btn_remove ms-3"
										onClick={() => {
											setSelectedAutomation(automation);
											setShowDeleteAutomationDialog(true);
										}}
									>
									<i className="fas fa-trash text-danger fs-4"></i>
								</span>
								</td>
							</tr>;
						})}
					</tbody>
				</table>
			</div>
			{showDeleteAutomationDialog && selectedAutomation && (
				<SkeDialogDelete
					onCancel={closeAndResetDialogs}
					onConfirm={handleConfirmDelete}
					successMessage="Automation deleted"
					message={`Are you sure to delete ${selectedAutomation.name}`}
				/>
			)}
		</>
	);
}
