import React from 'react'
import { ReasonType } from '../../../../types/ReasonType'
import { CreateReason } from './CreateReason'
import { EditReason } from './EditReason'
import ReasonsList from './ReasonsList'

type Props = {
    tenantId: string
}

export default function AttendanceReasons({ tenantId }: Props) {
    const [showPage, setShowPage] = React.useState('list')
    const [editReason, setEditReason] = React.useState<ReasonType | null>(null)
    return (
        <>
            {showPage === 'list' && <>
                <div className='min-h-300px'>
                    <ReasonsList tenantId={tenantId} setShowPage={setShowPage} setEditReason={setEditReason} />
                </div>
            </>}
            {showPage === 'create' && <CreateReason tenantId={tenantId} setShowPage={setShowPage} />}
            {showPage === 'edit' && !!editReason && <EditReason setShowPage={setShowPage} editReason={editReason} />}
        </>
    )
}