import React from 'react'
import { useParams, Route, Routes, Navigate } from "react-router-dom";
import { PageLink, PageTitle } from '../../../_library/layout/core'
import { EditCompanyHeader } from './components/EditCompanyHeader'
import { CompanyProfileEdit } from './components/CompanyProfileEdit'
import { CoachingEdit } from './components/CoachingEdit'
import { RecognitionEdit } from './components/RecognitionEdit'
import { DisciplineStepsEdit } from './components/DisciplineStepsEdit'
import { AttendancesEdit } from './components/AttendancesEdit'

const accountBreadCrumbs: Array<PageLink> = [
    {
        title: 'Companies',
        path: '',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Edit',
        path: '',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

type RouteParams = {
    companyId: string
}

function EditCompany() {
    const { companyId } = useParams<RouteParams>();

    return (
        <>
            <EditCompanyHeader companyId={(companyId as string)} />
            <Routes>
                <Route path='profile' element={
                    <>
                        <PageTitle breadcrumbs={accountBreadCrumbs}>Profile</PageTitle>
                        <CompanyProfileEdit />
                    </>
                } />
                <Route path='disciplines' element={
                    <>
                        <PageTitle breadcrumbs={accountBreadCrumbs}>Discipline</PageTitle>
                        <DisciplineStepsEdit />
                    </>
                } />
                <Route path='attendances' element={
                    <>
                        <PageTitle breadcrumbs={accountBreadCrumbs}>Attendances</PageTitle>
                        <AttendancesEdit />
                    </>
                } />
                <Route path="coaching" element={
                    <>
                        <PageTitle breadcrumbs={accountBreadCrumbs}>Coaching</PageTitle>
                        <CoachingEdit />
                    </>
                } />
                <Route path="recognition" element={
                    <>
                        <PageTitle breadcrumbs={accountBreadCrumbs}>Recognition</PageTitle>
                        <RecognitionEdit />
                    </>
                } />
                <Route path='*' element={<Navigate to='profile' /> } />
            </Routes>
        </>
    )
}

export { EditCompany }
