import { AuthModel } from './AuthModel'
import { UserAddressModel } from './UserAddressModel'
import { UserCommunicationModel } from './UserCommunicationModel'
import { UserEmailSettingsModel } from './UserEmailSettingsModel'
import { UserSocialNetworksModel } from './UserSocialNetworksModel'
import { CompanyType, TeamType } from '../types/CompanyType'

export type UserRole =
    'SecchiAdmin' |
    'SuperAdmin' |
    'Provisioner' |
    'Supervisor'

export enum UserRoleEnum {
  SecchiAdmin = 'SecchiAdmin',
  SuperAdmin = 'SuperAdmin',
  Provisioner = 'Provisioner',
  Supervisor = 'Supervisor',
}

export interface UserModel {
  id: number
  username: string
  email: string
  isEmailVerified: boolean
  firstName: string
  lastName: string
  fullname?: string
  occupation?: string
  company?: CompanyType
  phone?: string
  roles?: UserRole[]
  pic?: string
  avatarUrl?: string
  language?: 'en' | 'de' | 'es' | 'fr' | 'ja' | 'zh' | 'ru'
  timeZone?: string
  website?: 'https://keenthemes.com'
  emailSettings?: UserEmailSettingsModel
  auth?: AuthModel
  communication?: UserCommunicationModel
  address?: UserAddressModel
  socialNetworks?: UserSocialNetworksModel,
  teams: TeamType[]
}
