import { useFormik } from 'formik';
import { capitalize, groupBy, omit, sortBy, values } from 'lodash';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { shallowEqual, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { object, string } from 'yup';
import { SkeDialogTextPrompt } from '../../../common/components/dialog-text-prompt';
import { CategoryModel, CategorySubtypeEnum, CategoryTypeEnum } from '../../../features/category/models/category.model';
import { Company } from '../../../features/company/models/company.model';
import {
	TemplateCreateBase,
	TemplateCreateWithExistingCategory,
	TemplateCreateWithNewCategory,
	TemplateTypeEnum,
} from '../../../features/template/models/template.model';
import { RootState } from '../../../setup';
import { createTemplate, getCategories, getCompanies, updateTemplate } from '../../modules/CRUD/CRUD';
import { CategoryTemplateFlattened } from './TemplatesPage';

export interface EditTemplateProps {
	flatTemplate?: CategoryTemplateFlattened;
	onSave: () => void;
	mode: 'CREATE' | 'UPDATE';
}

export interface TemplatePlaceholder {
	name: string;
	description: string;
	type: TemplatePlaceholderType.Discipline;
	content: string;
	token: string;
}

export enum TemplatePlaceholderType {
	Discipline = 'DISCIPLINE',
}

// TODO: Split out, store in DB
export const TEMPLATE_PLACEHOLDERS: {[key: string]: TemplatePlaceholder} = {
	employeeFullName: {
		name: 'Employee Name',
		description: 'Employee first and last name',
		type: TemplatePlaceholderType.Discipline,
		content: '{employeeFullName}',
		token: 'employeeFullName',
	},
	employeeFirstName: {
		name: 'Employee First Name',
		description: 'Employee first name',
		type: TemplatePlaceholderType.Discipline,
		content: '{employeeFirstName}',
		token: 'employeeFirstName',
	},
	employeeLastName: {
		name: 'Employee Last Name',
		description: 'Employee Last name',
		type: TemplatePlaceholderType.Discipline,
		content: '{employeeLastName}',
		token: 'employeeLastName',
	},
	employeeHireDate: {
		name: 'Hire Date',
		description: 'Hire date of the employee',
		type: TemplatePlaceholderType.Discipline,
		content: '{employeeHireDate}',
		token: 'employeeHireDate',
	},
	currentDisciplineStepName: {
		name: 'Discipline Step',
		description: 'The step that this Corrective Action is on',
		type: TemplatePlaceholderType.Discipline,
		content: '{currentDisciplineStepName}',
		token: 'currentDisciplineStepName',
	},
	currentDisciplineDate: {
		name: 'Discipline Date',
		description: 'The date that this Corrective Action is marked for',
		type: TemplatePlaceholderType.Discipline,
		content: '{currentDisciplineDate}',
		token: 'currentDisciplineDate',
	},
	// currentDisciplineSupervisorNote: {
	// 	name: 'Supervisor Note',
	// 	description: 'DEPRECATED - this only worked with the old Corrective Action system prior to templates',
	// 	type: TemplatePlaceholderType.Discipline,
	// 	content: '{currentDisciplineSupervisorNote}',
	// 	content: '{currentDisciplineSupervisorNote}',
	// },
	currentAttendancePoints: {
		name: 'Attendance Points',
		description: 'How many attendance points the employee currently has on their record',
		type: TemplatePlaceholderType.Discipline,
		content: '{currentAttendancePoints}',
		token: 'currentAttendancePoints',
	},
	latestAttendanceDate: {
		name: 'Last Attendance Record - Date',
		description: 'Date of the last attendance record',
		type: TemplatePlaceholderType.Discipline,
		content: '{latestAttendanceDate}',
		token: 'latestAttendanceDate',
	},
	latestAttendancePoints: {
		name: 'Last Attendance Record - Points',
		description: 'How many points were added on the last attendance entry',
		type: TemplatePlaceholderType.Discipline,
		content: '{latestAttendancePoints}',
		token: 'latestAttendancePoints',
	},
	companyName: {
		name: 'Company Name',
		description: 'Name of the company',
		type: TemplatePlaceholderType.Discipline,
		content: '{companyName}',
		token: 'companyName',
	},
	locationName: {
		name: 'Team Location Name',
		description: 'Name of location, taken from the team info',
		type: TemplatePlaceholderType.Discipline,
		content: '{locationName}',
		token: 'locationName',
	},
};

const validationSchema = object({
	templateName: string().required(),
	templateDescription: string().optional(),
	incidentDetails: string().optional(),
	expectations: string().optional(),
	consequences: string().optional(),
});

export function EditTemplate({
															 flatTemplate,
															 onSave,
															 mode,
														 }: EditTemplateProps) {
	const token: string = useSelector<RootState>(
		({auth}) => auth.accessToken,
		shallowEqual,
	) as string;
	const [selectedCompany, setSelectedCompany] = useState<Company>();
	const [selectedCategory, setSelectedCategory] = useState<CategoryModel>();
	const [companies, setCompanies] = useState<Company[]>([]);
	const [selectedPlaceholder, setSelectedPlaceholder] = useState<TemplatePlaceholder>(TEMPLATE_PLACEHOLDERS.employeeFullName);
	const [showNewCategory, setShowNewCategory] = useState<boolean>(false);
	const [newCategorySubtype, setNewCategorySubtype] = useState<CategorySubtypeEnum>();
	const [newCategoryInfo, setNewCategoryInfo] = useState<{name: string, subtype: CategorySubtypeEnum}>();
	const [createCategoryWithTemplate, setCreateCategoryWithTemplate] = useState<boolean>(false);
	const [groupedCategories, setGroupedCategories] = useState<{ [key in CategorySubtypeEnum]: CategoryModel[] }>({
		[CategorySubtypeEnum.Attendance]: [],
		[CategorySubtypeEnum.Performance]: [],
	});
	const [ungroupedCategories, setUngroupedCategories] = useState<CategoryModel[]>([]);

	const getExistingTemplateOrEmpty = () => {
		if (!!flatTemplate) {
			return {
				incidentDetails: flatTemplate.template.fields.incidentDetails,
				expectations: flatTemplate.template.fields.expectations,
				consequences: flatTemplate.template.fields.consequences,
				templateName: flatTemplate?.templateName,
				// react doesn't like null values for textareas
				templateDescription: flatTemplate.templateDescription || '',
			};
		}
		return {
			incidentDetails: '',
			expectations: '',
			consequences: '',
			templateName: '',
			templateDescription: '',
		};
	};

	const getCategoriesForCompany = (tenant_id: string) => {
		getCategories(token, {
			tenant_id,
			type: [CategoryTypeEnum.Discipline],
		})
			.then(res => {
				const rawCategories: CategoryModel[] = res.data;
				setUngroupedCategories(rawCategories);
				const groupedCategories = groupBy(rawCategories, 'subtype');
				setGroupedCategories({
					[CategorySubtypeEnum.Performance]: sortBy(groupedCategories[CategorySubtypeEnum.Performance], 'name'),
					[CategorySubtypeEnum.Attendance]: sortBy(groupedCategories[CategorySubtypeEnum.Attendance], 'name'),
				});
				if (flatTemplate?.categoryId && !selectedCategory) {
					setCategoryFromId(flatTemplate.categoryId, rawCategories);
				}
			})
			.catch(err => {
				toast.error('Error getting categories for company');
				console.error(err);
			});
	};

	useEffect(() => {
		getCompanies(token)
			.then(({data}) => {
				setCompanies(data.items);
				if (flatTemplate?.tenant_id && !selectedCompany) {
					setCompanyFromTenantId(flatTemplate.tenant_id, data.items);
				}
			})
			.catch((error) => console.error('Failed to fetch companies:', error));
	}, []);

	const handleChangePlaceholder = (newPlaceholder: string) => {
		setSelectedPlaceholder(TEMPLATE_PLACEHOLDERS[newPlaceholder]);
	};

	const handleCompanyChanged = (e: any) => {
		setSelectedCategory(undefined);
		const tenant_id = e.target.value;
		const newCompany = companies.find(cmp => cmp.id === tenant_id);
		setSelectedCompany(newCompany);
		getCategoriesForCompany(tenant_id);
	};

	const handleAddCategory = (type: CategorySubtypeEnum) => {
		setNewCategorySubtype(type);
		setShowNewCategory(true);
	};

	const setCompanyFromTenantId = (tenant_id: string, cmpList: Company[] = companies): void => {
		if (cmpList.length > 0) {
			const company = cmpList.find(cmp => cmp.id === tenant_id);
			setSelectedCompany(company);
			getCategoriesForCompany(tenant_id);
		}
	};

	const setCategoryFromId = (cat_id: number, cats: CategoryModel[] = ungroupedCategories): void => {
		if (cats.length > 0) {
			const newCat = cats.find((cat: CategoryModel) => cat.id === cat_id);
			setSelectedCategory(newCat);
		}
	};

	useEffect(() => {
		formik.setValues(getExistingTemplateOrEmpty);
		if (flatTemplate?.tenant_id) {
			setCompanyFromTenantId(flatTemplate.tenant_id);
		}
		if (flatTemplate?.categoryId) {
			setCategoryFromId(flatTemplate.categoryId);
		}
	}, [flatTemplate]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: getExistingTemplateOrEmpty(),
		validationSchema,
		onSubmit: (values,
							 {
								 setStatus,
								 setSubmitting,
							 }) => {
			if (!selectedCompany) {
				return console.error('Unable to save template as no company is selected');
			}
			let saveData: TemplateCreateBase = {
				name: values.templateName,
				// react doesn't like null values for textareas, but want null for DB
				description: values.templateDescription || null,
				template: {
					fields: {
						...omit(values, [
							'templateName',
							'templateDescription',
						]),
					},
				},
				tenant_id: selectedCompany.id,
				// hardcoded until we open templates to more types of connections/data
				type: TemplateTypeEnum.Discipline,
			};
			if (mode === 'CREATE') {
				let saveWithNew: TemplateCreateWithNewCategory;
				let saveWithExisting: TemplateCreateWithExistingCategory;
				let pipe;
				if (!!selectedCategory) {
					saveWithExisting = {
						...saveData,
						category_id: selectedCategory.id,
					};
					pipe = createTemplate(saveWithExisting, token);
				} else {
					if (!newCategoryInfo) {
						return console.error('Missing required info to create a new category');
					}
					saveWithNew = {
						...saveData,
						category_name: newCategoryInfo.name,
						category_subtype: newCategoryInfo.subtype,
						tenant_id: selectedCompany.id,
					};
					pipe = createTemplate(saveWithNew, token);
				}

				return pipe
					.then(res => {
						reset();
						onSave();
					})
					.catch(console.error);
			}
			if (!flatTemplate?.templateId) {
				return console.error('Trying to update template, but template_id is falsy');
			}
			updateTemplate(flatTemplate.templateId, omit(saveData, [
				'tenant_id',
				'type',
			]), token)
				.then(() => {
					toast.success('Template updated successfully');
					reset();
					onSave();
				})
				.catch(err => {
					toast.error('Error updating template');
					console.error(err);
				});
		},
	});

	const reset = () => {
		setSelectedCompany(undefined);
		setSelectedCategory(undefined);
	};

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="mb-2">
						<fieldset>
							<legend>
								{mode === 'CREATE' ? 'New' : 'Edit'} Template
							</legend>
							<div className="d-flex flex-column">
								<Form.Label
									htmlFor="company-select">
									Company
								</Form.Label>
								<Form.Select
									id="company-select"
									className="mt-2"
									disabled={mode === 'UPDATE'}
									value={selectedCompany?.id}
									defaultValue=""
									onChange={e => handleCompanyChanged(e)}>
									<option
										value=""
										disabled>Select a company
									</option>
									{companies?.map((cmp: Company) => {
										return (
											<option
												key={cmp.id}
												value={cmp.id}>
												{cmp.name}
											</option>
										);
									})}
								</Form.Select>
								<div className="d-flex flex-row align-items-center">
									<Form.Label
										htmlFor="category-select"
										className="d-inline-block mb-0"
									>
										Category
									</Form.Label>
									<button
										type="button"
										disabled={!selectedCompany}
										onClick={() => handleAddCategory(CategorySubtypeEnum.Attendance)}
										className="btn btn-sm btn-outline-primary d-inline-block">+ Attendance Category
									</button>
									<button
										type="button"
										disabled={!selectedCompany}
										onClick={() => handleAddCategory(CategorySubtypeEnum.Performance)}
										className="btn btn-sm btn-outline-primary d-inline-block">+ Performance Category
									</button>
								</div>
								{!createCategoryWithTemplate && (
									<Form.Select
										className="mt-2"
										id="category-select"
										disabled={mode === 'UPDATE' || !selectedCompany}
										value={selectedCategory?.id}
										defaultValue=""
										onChange={e => {
											setCategoryFromId(+e.target.value);
										}}>
										<option
											disabled
											value="">Select a category
										</option>
										<optgroup
											label={capitalize(CategorySubtypeEnum.Attendance)}
										>
											{groupedCategories[CategorySubtypeEnum.Attendance].length === 0 && (
												<option
													disabled={true}
												>
													None
												</option>
											)}
											{groupedCategories[CategorySubtypeEnum.Attendance].map((cat: CategoryModel) => (
												<option
													key={cat.id}
													value={cat.id}>
													{cat.name}
												</option>
											))}
										</optgroup>
										<optgroup
											label={capitalize(CategorySubtypeEnum.Performance)}
										>
											{groupedCategories[CategorySubtypeEnum.Performance].length === 0 && (
												<option
													disabled={true}
												>
													None
												</option>
											)}
											{groupedCategories[CategorySubtypeEnum.Performance].map((cat: CategoryModel) => (
												<option
													key={cat.id}
													value={cat.id}>
													{cat.name}
												</option>
											))}
										</optgroup>
									</Form.Select>
								)}
								{createCategoryWithTemplate && (
									<ul className="list-unstyled">
										<li className="fst-italic fw-light">New category will be created when template is created.</li>
										<li>
											<span className="fw-bolde">Name: </span>
											<span className="fw-normal">{newCategoryInfo?.name}</span>
										</li>
										<li>
											<span className="fw-bold">Subtype: </span>
											<span className="fw-normal">{capitalize(newCategoryInfo?.subtype)}</span>
										</li>
										<li>
											<button
												type="button"
												onClick={() => {
													setNewCategoryInfo(undefined);
													setNewCategorySubtype(undefined);
													setShowNewCategory(false);
													setCreateCategoryWithTemplate(false);
												}}
												className="btn btn-outline-danger btn-sm">Reset Category
											</button>
										</li>
									</ul>
								)}
							</div>
						</fieldset>
					</div>
					<form onSubmit={formik.handleSubmit}>
						<div className="mb-3">
							<fieldset>
								<legend
									className="mt-1"
								>
									Template Info
								</legend>
							</fieldset>
							<label
								htmlFor="templateName"
								className="form-label">
								Name
							</label>
							<input
								type="text"
								id="templateName"
								className="form-control"
								{...formik.getFieldProps('templateName')}
							/>
							{formik.touched.templateName && formik.errors.templateName ? (
								<div className="text-danger">{formik.errors.templateName}</div>
							) : null}
						</div>
						<div className="mb-3">
							<label
								htmlFor="templateDesc"
								className="form-label">
								Template Description
							</label>
							<textarea
								id="templateDescription"
								className="form-control"
								{...formik.getFieldProps('templateDescription')}
							/>
							{formik.touched.templateDescription && formik.errors.templateDescription ? (
								<div className="text-danger">{formik.errors.templateDescription}</div>
							) : null}
						</div>
						<div
							className="d-flex flex-row">
							<div className="col-9 pe-2">
								<fieldset>
									<legend
										className="text-center">
										Form Fields
										{/*<h3>Form Fields</h3>*/}
									</legend>
									<div className="mb-3">
										<label
											htmlFor="incidentDetails"
											className="form-label">
											Incident Details
										</label>
										<textarea
											id="incidentDetails"
											className="form-control"
											{...formik.getFieldProps('incidentDetails')}
										/>
										{formik.touched.incidentDetails && formik.errors.incidentDetails ? (
											<div className="text-danger">{formik.errors.incidentDetails}</div>
										) : null}
									</div>

									<div className="mb-3">
										<label
											htmlFor="consequences"
											className="form-label">
											Consequences
										</label>
										<textarea
											id="consequences"
											className="form-control"
											{...formik.getFieldProps('consequences')}
										></textarea>
										{formik.touched.consequences && formik.errors.consequences ? (
											<div className="text-danger">{formik.errors.consequences}</div>
										) : null}
									</div>

									<div className="mb-3">
										<label
											htmlFor="expectations"
											className="form-label">
											Expectations
										</label>
										<textarea
											id="expectations"
											className="form-control"
											{...formik.getFieldProps('expectations')}
										></textarea>
										{formik.touched.expectations && formik.errors.expectations ? (
											<div className="text-danger">{formik.errors.expectations}</div>
										) : null}
									</div>

								</fieldset>
							</div>
							<div className="col-3">
								<fieldset
									className="ps-2">
									<legend
										className="text-center">Placeholders
									</legend>
									<Form.Select
										onChange={e => handleChangePlaceholder(e.target.value)}
									>
										{values(TEMPLATE_PLACEHOLDERS).map((itm: TemplatePlaceholder) => {
											return (
												<option
													value={itm.token}
													key={itm.token}
												>
													{itm.name}
												</option>
											);
										})}
									</Form.Select>
									{!!selectedPlaceholder && (
										<div className="mt-2 d-flex flex-column">
											<div className="fs-5 my-2">
												<span className="fw-bold me-2">Description:</span>
												{selectedPlaceholder.description}
											</div>
											<CopyToClipboard
												text={selectedPlaceholder.content}
												onCopy={() => toast.info('Copied', {
													autoClose: 500,
												})}
											>
												<span
													className="d-block p-4 rounded-2 bg-secondary cursor-pointer d-flex text-hover-primary align-content-center justify-content-evenly"
												>
													<span className="font-monospace text-hover-dark text-dark">
														{selectedPlaceholder.content}
													</span>
													<i
														className="bi bi-copy align-self-center"
													/>
												</span>
											</CopyToClipboard>
										</div>
									)}
								</fieldset>
							</div>
						</div>

						<button
							type="submit"
							className="btn btn-primary"
							disabled={!formik.dirty || (createCategoryWithTemplate && !newCategoryInfo) || formik.isSubmitting}>
							{mode === 'CREATE' && (
								<>
									{formik.isSubmitting ? 'Creating...' : 'Create Template'}
								</>
							)}
							{mode === 'UPDATE' && (
								<>
									{formik.isSubmitting ? 'Updating...' : 'Update Template'}
								</>
							)}
						</button>
					</form>
				</div>
			</div>
			{showNewCategory && (
				<SkeDialogTextPrompt
					confirmLabel="Confirm"
					title="New Category"
					message={`What is the new Discipline/Corrective Action category you'd like to create? This will be of type: ${capitalize(newCategorySubtype)}. This will be created when you submit the template for saving.`}
					variant="input"
					onCancel={() => {
						setShowNewCategory(false);
						setNewCategorySubtype(undefined);
						setCreateCategoryWithTemplate(false);
					}}
					onConfirm={(val: string) => {
						setNewCategoryInfo({
							name: val,
							subtype: newCategorySubtype!,
						});
						setCreateCategoryWithTemplate(true);
						setShowNewCategory(false);
						setNewCategorySubtype(undefined);
					}}
				/>
			)}
		</>
	);
}
