import React from 'react'
import { AttendanceRuleType } from '../../../../types/AttendanceRuleType'
import { CreateRule } from './CreateRule'
import { EditRule } from './EditRule'
import RulesList from './RulesList'

type Props = {
    tenantId: string
}

export default function AttendanceRules({ tenantId }: Props) {
    const [showPage, setShowPage] = React.useState('list')
    const [editRule, setEditRule] = React.useState<AttendanceRuleType | null>(null)

    return (
        <>
            {showPage === 'list' && <>
                <div className='min-h-300px'>
                    <RulesList tenantId={tenantId} setShowPage={setShowPage} setEditRule={setEditRule} />
                </div>
            </>}
            {showPage === 'create' && <CreateRule tenantId={tenantId} setShowPage={setShowPage} />}
            {showPage === 'edit' && !!editRule && <EditRule setShowPage={setShowPage} editRule={editRule} />}
        </>
    )
}