import axios, { AxiosPromise } from 'axios';
import { RequireExactlyOne, SetRequired } from 'type-fest';
import { ApiResponseWithoutPagination } from '../../../common/models/ApiResponse.model';
import { transformQueryParams } from '../../../common/utilities/common.api';
import { CategoryModel, CategorySubtypeEnum, CategoryTypeEnum } from '../../../features/category/models/category.model';
import { Company } from '../../../features/company/models/company.model';
import {
    TemplateCreateWithExistingCategory,
    TemplateCreateWithNewCategory,
    TemplateDuplicationPayloadAllOptions,
    TemplateModel, TemplateReplicationWithExistingCategory, TemplateReplicationWithNewCategory,
    TemplateTypeEnum,
} from '../../../features/template/models/template.model';
import { AutomationModel } from '../../models/AutomationModel'
import { UpdateCompanyModel } from '../../types/CompanyType'
import { DisciplineStepModel } from '../../models/DisciplineStepModel'
import { AttendanceReasonModel } from '../../models/AttendanceReasonModel'
import { AttendanceRuleModel } from '../../models/AttendanceRuleModel'
import { AttendanceAutomationModel } from '../../models/AttendanceAutomationModel'

const API_URL = process.env.REACT_APP_API_URL

export const USERS_URL = `${API_URL}/users`
export const COMPANIES_URL = `${API_URL}/companies`
// export const ACTIONS_URL = `${API_URL}/actions`
export const INVITE_SUPERADMIN_URL = `${API_URL}/auth/invite-superadmin`
export const SEND_INVITE_SUPERVISOR_URL = `${API_URL}/auth/invite-supervisor`
export const SEND_FORGOT_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const AUTOMATIONS_URL = `${API_URL}/automations`
export const ATTENDANCE_REASONS_URL = `${API_URL}/attendance-reasons`
export const ATTENDANCE_RULES_URL = `${API_URL}/attendance-rules`
export const ATTENDANCE_AUTOMATIONS_URL = `${API_URL}/attendance-automations`
export const COACHING_CATEGORIES_URL = `${API_URL}/coaching-categories`
export const COACHING_URL = `${API_URL}/coachings`
export const RECOGNITION_CATEGORIES_URL = `${API_URL}/recognition-categories`
export const RECOGNITION_URL = `${API_URL}/recognitions`
export const DISCIPLINE_STEPS_URL = `${API_URL}/discipline-steps`
export const CATEGORIES_URL = `${API_URL}/category`
export const TEMPLATES_URL = `${API_URL}/templates`

export interface GetCategoriesOpts {
    type?: CategoryTypeEnum[];
    subtype?: CategorySubtypeEnum[];
    tenant_id?: string|string[];
}

export function getCategories(token: string, opts?: GetCategoriesOpts): AxiosPromise<CategoryModel[]> {
    console.log('getCats opts', opts);
    return axios({
        method: 'get',
        url: `${CATEGORIES_URL}`,
        params: opts,
        paramsSerializer: params => transformQueryParams(params),
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export function createTemplate(data: TemplateCreateWithNewCategory | TemplateCreateWithExistingCategory, token: string) {
    return axios({
        method: 'post',
        url: `${TEMPLATES_URL}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: data
    })
}

export function getTemplates(token: string, Types: TemplateTypeEnum[]) {
    return axios ({
        method: 'get',
        url: `${TEMPLATES_URL}`,
        headers: {
            'Authorization':`Bearer ${token}`
        }
    })
}

export function getTemplate(templateId: number, token: string) {
    return axios ({
        method: 'get',
        url: `${TEMPLATES_URL}/${templateId}`,
        headers: {
            'Authorization':`Bearer ${token}`
        }
    })
}

export function replicateTemplates (
    // data: Partial<TemplateDuplicationPayloadAllOptions>,
    data: TemplateReplicationWithExistingCategory | TemplateReplicationWithNewCategory,
    // data: RequireExactlyOne<TemplateDuplicationPayloadAllOptions, 'categoryId' | 'categoryName'>,
    token: string,
): AxiosPromise<string> {
    return axios({
      method: 'post',
      url: `${TEMPLATES_URL}/replicate`,
      headers: {
        'Authorization': `Bearer ${token}`
      },
      data: data
    });
}


export function deleteTemplate(templateId: number, token: string) {
    return axios({
        method: 'delete',
        url: `${TEMPLATES_URL}/${templateId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
        }

    })
}

export function updateTemplate(
    templateId: number,
    data: Partial<TemplateModel>,
    token: string
) {
    console.log('got request to update template', templateId, data);
    return axios({
        method: 'patch',
        url: `${TEMPLATES_URL}/${templateId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        data: data
    })
}



export function getUser(userId: number, token: string) {
    return axios({
        method: 'get',
        url: `${USERS_URL}/${userId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export function getUsers(token: string) {
    return axios({
        method: 'get',
        url: `${USERS_URL}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export function getUsersFromUrl(url: string, token: string) {
    return axios({
        method: 'get',
        url: `${USERS_URL}?${url}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export function createUser(
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    passwordConfirmation: string,
    roles: string[],
    token: string
) {
    return axios({
        method: 'post',
        url: USERS_URL,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            email,
            firstName,
            lastName,
            password,
            passwordConfirmation,
            roles
        }
    })
}

export function setUserAvatar(
    userId: number,
    requestBody: FormData,
    token: string
) {
    return axios.post(`${USERS_URL}/${userId}/avatar`, requestBody,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        }
    )
}

export function deleteUser(
    userId: number,
    token: string
) {
    return axios({
        method: 'delete',
        url: `${USERS_URL}/${userId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export function updateUser(
    userId: number,
    firstName: string,
    lastName: string,
    email: string,
    isEmailVerified: boolean,
    isLockedOut: boolean,
    roles: string[],
    token: string
) {
    return axios({
        method: 'patch',
        url: `${USERS_URL}/${userId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: {
            firstName,
            lastName,
            email,
            isEmailVerified,
            isLockedOut,
            roles
        }
    })
}

export function createCompany(
    name: string,
    size: string,
    website: string,
    industry: string,
    country: string,
    attendanceType: string,
    performanceType: string,
    rollover: string,
    ownerId: number,
    attendanceStartDate: string,
    token: string
) {
    const data = {
        name,
        size,
        website,
        industry,
        country,
        attendanceType,
        performanceType,
        rollover,
        attendanceStartDate,
        ownerId: ownerId
    };

    return axios({
        method: 'post',
        url: COMPANIES_URL,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: data
    })
}

export function getCompany(companyId: string, token: string) {
    return axios({
        method: 'get',
        url: `${COMPANIES_URL}/${companyId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export function getCompanies(token: string): AxiosPromise<ApiResponseWithoutPagination<Company>> {
    return axios({
        method: 'get',
        url: `${COMPANIES_URL}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export function deleteCompany(
    companyId: string,
    token: string
) {
    return axios({
        method: 'delete',
        url: `${COMPANIES_URL}/${companyId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export function updateCompany(
    companyId: string,
    data: UpdateCompanyModel,
    token: string
) {
    return axios({
        method: 'patch',
        url: `${COMPANIES_URL}/${companyId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: data
    })
}
export function inviteSuperadmin(email: string, token: string) {
    return axios({
        method: 'post',
        url: INVITE_SUPERADMIN_URL,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: {
            email: email
        }
    })
}

export function inviteSupervisor(email: string, token: string) {
    return axios({
        method: 'post',
        url: SEND_INVITE_SUPERVISOR_URL,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: {
            email: email
        }
    })
}
export function sendForgotPassword(email: string, token: string) {
    return axios({
        method: 'post',
        url: SEND_FORGOT_PASSWORD_URL,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: {
            email: email
        }
    })
}

export function getAutomations(companyId: number, token: string) {
    return axios({
        method: 'get',
        url: `${AUTOMATIONS_URL}?companyId=${companyId}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export function createAutomation(companyId: number, automation: AutomationModel, token: string) {
    return axios({
        method: 'post',
        url: AUTOMATIONS_URL,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: {
            ...automation,
            companyId: companyId
        }
    })
}

export function updateAutomation(automationId: number, data: AutomationModel, token: string) {
    return axios({
        method: 'patch',
        url: `${AUTOMATIONS_URL}/${automationId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: data
    })
}

export function updateAutomationActive(automationId: number, active: boolean, token: string) {
    return axios({
        method: 'patch',
        url: `${AUTOMATIONS_URL}/${automationId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: {
            isActive: active
        }
    })
}

export function removeAutomation(automationId: number, reasonForDeleting: string, token: string) {
    return axios({
        method: 'delete',
        url: `${AUTOMATIONS_URL}/${automationId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        data: {
            reasonForDeleting
        }
    })
}

export function createUnexcuse(companyId: number, reason: string, token: string) {
    // return axios({
    //     method: 'post',
    //     url: EXCUSES_URL,
    //     headers: {
    //         'Authorization': `Bearer ${token}`,
    //         'Content-Type': 'application/json'
    //     },
    //     data: {
    //         isExcused: false,
    //         content: reason,
    //         companyId: companyId
    //     }
    // })
}

export function sendSupervisorInvite(companyId: number, email: string, token: string) {
    return axios({
        method: 'post',
        url: SEND_INVITE_SUPERVISOR_URL,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: {
            "email": email,
            companyId: companyId
        }
    })
}


export function getReasons(companyId: number, token: string) {
    // return axios({
    //     method: 'get',
    //     url: `${EXCUSES_URL}?companyId=${companyId}`,
    //     headers: {
    //         'Authorization': `Bearer ${token}`,
    //     }
    // })
}

export function updateReason(reasonId: number, isExcused: boolean, content: string, token: string) {
    // return axios({
    //     method: 'patch',
    //     url: `${EXCUSES_URL}/${reasonId}`,
    //     headers: {
    //         'Authorization': `Bearer ${token}`,
    //         'Content-Type': 'application/json'
    //     },
    //     data: {
    //         isExcused,
    //         content
    //     }
    // })
}

export function removeReason(reasonId: number, token: string) {
    // return axios({
    //     method: 'delete',
    //     url: `${EXCUSES_URL}/${reasonId}`,
    //     headers: {
    //         'Authorization': `Bearer ${token}`,
    //     }
    // })
}

//  Coaching Category
export function createCoachingCategory(tenantId: string, name: string, token: string) {
    return axios({
        method: 'post',
        url: COACHING_CATEGORIES_URL,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: {
            tenant_id: tenantId,
            name: name
        }
    })
}

export function getCoachingCategories(tenantId: string, token: string) {
    return axios({
        method: 'get',
        url: `${COACHING_CATEGORIES_URL}?tenant_id=${tenantId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
}

export function updateCoachingCategory(categoryId: number, name: string, token: string) {
    return axios({
        method: 'patch',
        url: `${COACHING_CATEGORIES_URL}/${categoryId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: {
            name: name
        }
    })
}

export function removeCoachingCategory(categoryId: number, token: string) {
    return axios({
        method: 'delete',
        url: `${COACHING_CATEGORIES_URL}/${categoryId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
}

// Recognition Category
export function createRecognitionCategory(tenantId: string, name: string, token: string) {
    return axios({
        method: 'post',
        url: RECOGNITION_CATEGORIES_URL,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: {
            tenant_id: tenantId,
            name: name
        }
    })
}

export function getRecognitionCategories(tenantId: string, token: string) {
    return axios({
        method: 'get',
        url: `${RECOGNITION_CATEGORIES_URL}?tenant_id=${tenantId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
}

export function updateRecognitionCategory(categoryId: number, name: string, token: string) {
    return axios({
        method: 'patch',
        url: `${RECOGNITION_CATEGORIES_URL}/${categoryId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: {
            name: name
        }
    })
}

export function removeRecognitionCategory(categoryId: number, token: string) {
    return axios({
        method: 'delete',
        url: `${RECOGNITION_CATEGORIES_URL}/${categoryId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
}

// Discipline Steps
export function getDisciplineStepsByType(tenant_id: string, type: 'Attendance' | 'Performance', isArchived: boolean = false, token: string) {
    return axios({
        method: 'get',
        url: `${DISCIPLINE_STEPS_URL}?tenant_id=${tenant_id}&type=${type}&isArchived=${isArchived}`,
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
}

export function getDisciplineSteps(tenant_id: string, isArchived: boolean = false, token: string) {
    return axios({
        method: 'get',
        url: `${DISCIPLINE_STEPS_URL}?tenant_id=${tenant_id}&isArchived=${isArchived}`,
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
}

export function createDisciplineStep(data: DisciplineStepModel, token: string) {
    return axios({
        method: 'post',
        url: DISCIPLINE_STEPS_URL,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: data
    })
}

export function updateDisciplineStep(stepId: number, data: DisciplineStepModel, token: string) {
    return axios({
        method: 'patch',
        url: `${DISCIPLINE_STEPS_URL}/${stepId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: data
    })
}

export function removeDisciplineStep(stepId: number, token: string) {
    return axios({
        method: 'delete',
        url: `${DISCIPLINE_STEPS_URL}/${stepId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
}

// Attendance Reasons
export function createAttendanceReason(tenant_id: string, content: string, isExcused: boolean, token: string) {
    return axios({
        method: 'post',
        url: ATTENDANCE_REASONS_URL,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: {
            tenant_id: tenant_id,
            content: content,
            isExcused: isExcused,
        }
    })
}

export function getAttendanceReasonsByIsExcused(tenant_id: string, isExcused: boolean, isArchived: boolean, token: string) {
    return axios({
        method: 'get',
        url: `${ATTENDANCE_REASONS_URL}?tenant_id=${tenant_id}&isExcused=${isExcused}&isArchived=${isArchived}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export function getAttendanceReasons(tenant_id: string, isArchived: boolean, token: string) {
    return axios({
        method: 'get',
        url: `${ATTENDANCE_REASONS_URL}?tenant_id=${tenant_id}&isArchived=${isArchived}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export function updateAttendanceReason(reasonId: number, data: AttendanceReasonModel, token: string) {
    return axios({
        method: 'patch',
        url: `${ATTENDANCE_REASONS_URL}/${reasonId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: data
    })
}

export function removeAttendanceReason(reasonId: number, token: string) {
    return axios({
        method: 'delete',
        url: `${ATTENDANCE_REASONS_URL}/${reasonId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
}

// Attendance Rules
export function createAttendanceRule(tenant_id: string, name: string, occurrence: string, pointsToAssign: number, token: string) {
    return axios({
        method: 'post',
        url: ATTENDANCE_RULES_URL,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: {
            tenant_id: tenant_id,
            name: name,
            occurrence: occurrence,
            pointsToAssign: pointsToAssign
        }
    })
}

export function getAttendanceRules(tenant_id: string, isArchived: boolean, token: string) {
    return axios({
        method: 'get',
        url: `${ATTENDANCE_RULES_URL}?tenant_id=${tenant_id}&isArchived=${isArchived}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}


export function updateAttendanceRule(ruleId: number, data: AttendanceRuleModel, token: string) {
    return axios({
        method: 'patch',
        url: `${ATTENDANCE_RULES_URL}/${ruleId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: data
    })
}

export function removeAttendanceRule(ruleId: number, token: string) {
    return axios({
        method: 'delete',
        url: `${ATTENDANCE_RULES_URL}/${ruleId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
}

// Attendance Automations
export function createAttendanceAutomation(tenant_id: string, name: string, description: string, triggerPoints: number, disciplineStepId: number, token: string) {
    return axios({
        method: 'post',
        url: ATTENDANCE_AUTOMATIONS_URL,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: {
            tenant_id: tenant_id,
            name: name,
            description: description,
            triggerPoints: triggerPoints,
            disciplineStepId: disciplineStepId
        }
    })
}

export function getAttendanceAutomations(tenant_id: string, isArchived: boolean, token: string) {
    return axios({
        method: 'get',
        url: `${ATTENDANCE_AUTOMATIONS_URL}?tenant_id=${tenant_id}&isArchived=${isArchived}`,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}


export function updateAttendanceAutomation(automationId: number, data: AttendanceAutomationModel, token: string) {
    return axios({
        method: 'patch',
        url: `${ATTENDANCE_AUTOMATIONS_URL}/${automationId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: data
    })
}

export function removeAttendanceAutomation(automationId: number, token: string) {
    return axios({
        method: 'delete',
        url: `${ATTENDANCE_AUTOMATIONS_URL}/${automationId}`,
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
}
