import React from 'react'
import { KTSVG } from '../../../../_library/helpers'
import AttendanceAutomations from './attendance/AttendanceAutomations'
import AttendanceReasons from './attendance/AttendanceReasons'
import AttendanceRules from './attendance/AttendanceRules'

import './styles/attendance.scss'

type Props = {
    companyId: string
    goPrev: () => void
    goNext: () => void
}

export function AttendanceStep({ companyId, goPrev, goNext }: Props) {

    return (
        <>
            <div className='d-flex flex-stack mb-5'>
                <div className='me-2'>
                    <button
                        onClick={goPrev}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                    >
                        <KTSVG
                            path='/media/icons/duotune/arrows/arr063.svg'
                            className='svg-icon-4 me-1'
                        />
                        Previous
                    </button>
                </div>

                <div>
                    <button
                        type='button'
                        className='btn btn-md btn-primary me-3'
                        onClick={goNext}
                    >
                        <span className='indicator-label'>
                            Next
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr064.svg'
                                className='svg-icon-3 ms-2 me-0'
                            />
                        </span>
                    </button>
                </div>
            </div>
            <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                <li className="nav-item">
                    <a
                        className="nav-link active"
                        data-bs-toggle="tab"
                        href="#atn_tab_pane_1"
                    >
                        Reasons
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#atn_tab_pane_2"
                    >
                        Rules
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#atn_tab_pane_3"
                    >
                        Automations
                    </a>
                </li>
            </ul>
            <div className="tab-content" id="AttendanceTabContent">
                <div
                    className="tab-pane fade active show"
                    id="atn_tab_pane_1"
                    role="tabpanel"
                >
                    <AttendanceReasons tenantId={companyId} />
                </div>
                <div className="tab-pane fade" id="atn_tab_pane_2" role="tabpanel">
                    <AttendanceRules tenantId={companyId} />
                </div>
                <div className="tab-pane fade" id="atn_tab_pane_3" role="tabpanel">
                    <AttendanceAutomations tenantId={companyId} />
                </div>
            </div>
        </>
    )
}