import { useParams } from 'react-router-dom'
import invariant from "tiny-invariant";
import AttendanceReasons from '../../modals/components/attendance/AttendanceReasons'
import AttendanceRules from '../../modals/components/attendance/AttendanceRules'
import AttendanceAutomations from '../../modals/components/attendance/AttendanceAutomations'

export function AttendancesEdit() {
    const { companyId } = useParams();
    invariant(companyId, 'Company id falsy in this route');

    return (
        <div className="card">
            {/* <div className="card-header pt-5"></div> */}
            <div className="card-body py-10">
                <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                    <li className="nav-item">
                        <a
                            className="nav-link active"
                            data-bs-toggle="tab"
                            href="#atn_tab_pane_1"
                        >
                            Reasons
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className="nav-link"
                            data-bs-toggle="tab"
                            href="#atn_tab_pane_2"
                        >
                            Rules
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className="nav-link"
                            data-bs-toggle="tab"
                            href="#atn_tab_pane_3"
                        >
                            Automations
                        </a>
                    </li>
                </ul>
                <div className="tab-content" id="AttendanceTabContent">
                    <div
                        className="tab-pane fade active show"
                        id="atn_tab_pane_1"
                        role="tabpanel"
                    >
                        <AttendanceReasons tenantId={companyId} />
                    </div>
                    <div className="tab-pane fade" id="atn_tab_pane_2" role="tabpanel">
                        <AttendanceRules tenantId={companyId} />
                    </div>
                    <div className="tab-pane fade" id="atn_tab_pane_3" role="tabpanel">
                        <AttendanceAutomations tenantId={companyId} />
                    </div>
                </div>
            </div>
        </div>
    )
}
