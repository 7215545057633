import React, { useEffect } from 'react'
import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik'
import * as Yup from 'yup'

import { createAttendanceReason } from '../../../../modules/CRUD/CRUD';
import { KTSVG } from '../../../../../_library/helpers'
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../../setup';

interface IReason {
    content: string,
    isExcused: boolean,
}

type Props = {
    setShowPage: (page: string) => void
    tenantId: string
}

export function CreateReason({ setShowPage, tenantId }: Props) {
    const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
    const [loading, setLoading] = React.useState(false)

    const initValues: IReason = {
        content: '',
        isExcused: false
    }

    const reasonSchema = Yup.object().shape({
        content: Yup.string()
            .required('Reason content is required'),
    })

    const handleSubmit = (values: IReason, actions: FormikValues) => {
        setLoading(true)
        setTimeout(() => {
            createAttendanceReason(tenantId, values.content, values.isExcused, token)
                .then(() => {
                    setShowPage('list')
                    setLoading(false)
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                })
        }, 1000)
    }

    useEffect(() => {
        let abort = new AbortController()

        return () => {
            abort.abort()
        }
    }, [])

    return (
        <div className='w-100'>
            <Formik
                validationSchema={reasonSchema}
                initialValues={initValues}
                onSubmit={handleSubmit}
            >
                {({ submitForm, isValid, dirty, handleChange, values, getFieldProps }) => (
                    <Form className='form py-5 w-100' noValidate id='kt_modal_create_onboarding_form'>
                        <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                                <span className='required'>Reason Content</span>
                            </label>

                            <Field
                                type='text'
                                className='form-control form-control-lg form-control-solid'
                                name='content'
                                placeholder=''
                            />
                            <div className='text-danger'>
                                <ErrorMessage name='content' />
                            </div>
                        </div>

                        <div className='fv-row mb-7'>
                            <div className="form-check form-check-custom form-check-solid">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexCheckDefault"
                                    {...getFieldProps("isExcused")}
                                    checked={values.isExcused}
                                />
                                <label className="form-check-label fw-bolder text-dark fs-6" htmlFor="flexCheckDefault">
                                    isExcused
                                </label>
                            </div>
                        </div>

                        <div className='d-flex flex-stack pt-10'>
                            <div className='me-2'>
                            </div>

                            <div>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-secondary me-3'
                                    onClick={() => setShowPage('list')}
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    className='btn btn-sm btn-primary me-3'
                                >
                                    {!loading && <span className='indicator-label'>
                                        Add
                                        <KTSVG
                                            path='/media/icons/duotune/arrows/arr064.svg'
                                            className='svg-icon-3 ms-2 me-0'
                                        />
                                    </span>}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}