import { entries, isArray, isNil, isObject } from 'lodash';

export const transformQueryParams = (params: {[key: string]: unknown}, removeFalsy = true) => {
   let options: string[] = [];
   entries(params).map(([key, val]) => {
     if (!isObject(val) && (!removeFalsy || (removeFalsy && !isNil(val)))) {
       return options.push(`${key}=${val}`);
     }
     if (isArray(val)) {
       return val.map(itm => options.push(`${key}=${itm}`));
     }
   });
   return (options.length === 0) ? '' : `${options.join('&')}`;
};
