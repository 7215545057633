import { Suspense } from 'react'
import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from '../../_library/layout/MasterLayout'
import { FallbackView } from '../../_library/partials'
import CompaniesPage from "../pages/companies/CompaniesPage";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { CreateUser } from '../pages/users/CreateUser'
import { EditUser } from '../pages/users/EditUser'
import { EditCompany } from '../pages/companies/EditCompany'
import UsersPage from "../pages/users/UsersPage";
import { TemplatesPage } from '../pages/templates/TemplatesPage';

export function PrivateRoutes() {

  return (
    <Suspense fallback={<FallbackView />}>
      <MasterLayout>
        <Routes>
          <Route path='/users' element={<UsersPage />} />
          <Route path='/create-user' element={<CreateUser />} />
          <Route path='/edit-user/:userId' element={<EditUser />} />
          <Route path='/companies' element={<CompaniesPage />} />
          <Route path='/templates' element={<TemplatesPage />} />
          <Route path='/dashboard' element={<DashboardWrapper />} />
          <Route path='/edit-company/:companyId/*' element={<EditCompany />} />
          <Route path='/auth/*' element={<Navigate to='/users' />} />
          <Route path='/' element={<Navigate to='/users' />} />
          <Route path="*" element={<Navigate to='error/404' />} />
        </Routes>
      </MasterLayout>
    </Suspense>
  )
}
