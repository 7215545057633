import React, { useEffect } from 'react'
import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik'
import * as Yup from 'yup'
import { ColorPicker, useColor } from "react-color-palette"
import "react-color-palette/lib/css/styles.css"

import { updateDisciplineStep } from '../../../../modules/CRUD/CRUD'
import { KTSVG } from '../../../../../_library/helpers'
import { DisciplineStepType } from '../../../../types/DisciplineStepType'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../../setup'

interface IStep {
    stepName: string,
    stepType: 'Attendance' | 'Performance',
    stepNumber: number,
    // stepColor: string,
}

type Props = {
    setShowPage: (page: string) => void
    editStep: DisciplineStepType
}

export function EditDisciplineStep({ setShowPage, editStep }: Props) {
    const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
    const [loading, setLoading] = React.useState(false)
    const [color, setColor] = useColor("hex", editStep.stepColor)
    const [showPicker, setShowPicker] = React.useState(false)

    const initValues: IStep = {
        stepName: editStep.name,
        stepType: editStep.type,
        stepNumber: editStep.stepNumber,
        // stepColor: '',
    }

    const profileSchema = Yup.object().shape({
        stepName: Yup.string()
            .required('Step name is required'),
        stepType: Yup.string()
            .required('Type is required'),
        stepNumber: Yup.string()
            .required('Step Number is required'),
        // stepColor: Yup.string()
        //     .required('Step Color is required'),
    })

    const handleShowPicker = () => {
        setShowPicker(false)
    }

    useEffect(() => {
        let abortController = new AbortController()

        window.addEventListener('click', handleShowPicker)

        return () => {
            abortController.abort()
            window.removeEventListener('click', handleShowPicker)
        }
    }, [])

    const handleSubmit = (values: IStep, actions: FormikValues) => {
        setLoading(true)
        setTimeout(() => {
            updateDisciplineStep(
                editStep.id,
                {
                    tenant_id: editStep.tenant_id,
                    name: values.stepName,
                    stepColor: color.hex,
                    stepNumber: values.stepNumber,
                    type: values.stepType,
                }, token)
                .then(() => {
                    setShowPage('list')
                    setLoading(false)
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                })
        }, 1000)
    }

    return (
        <div className='w-100'>
            <Formik
                validationSchema={profileSchema}
                initialValues={initValues}
                onSubmit={handleSubmit}
            >
                {({ submitForm, isValid, dirty, handleChange, values }) => (
                    <Form className='form py-5 w-100' noValidate id='kt_modal_create_onboarding_form'>
                        <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                                <span className='required'>Step Name</span>
                            </label>

                            <Field
                                type='text'
                                className='form-control form-control-lg form-control-solid'
                                name='stepName'
                                placeholder=''
                            />
                            <div className='text-danger'>
                                <ErrorMessage name='stepName' />
                            </div>
                        </div>

                        <div className="row mb-10">
                            <div className='col-12 fv-row'>
                                <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                    <span className='required'>Step Type</span>
                                </label>

                                <Field
                                    as='select'
                                    name='stepType'
                                    className='form-select form-select-solid'
                                >
                                    <option value='Attendance'>Attendance</option>
                                    <option value='Performance'>Performance</option>
                                </Field>
                                <div className='text-danger'>
                                    <ErrorMessage name='stepType' />
                                </div>
                            </div>
                        </div>

                        <div className='row mb-10'>
                            <div className='col-6 fv-row'>
                                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                                    <span className='required'>Step Number</span>
                                </label>

                                <Field
                                    type='number'
                                    className='form-control form-control-lg form-control-solid'
                                    name='stepNumber'
                                    placeholder=''
                                />
                                <div className='text-danger'>
                                    <ErrorMessage name='stepNumber' />
                                </div>
                            </div>
                            <div className='col-6 fv-row'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setShowPicker(true)
                                }}
                            >
                                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                                    <span className='required'>Step Color</span>
                                </label>

                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    // name='stepColor'
                                    placeholder=''
                                    value={color.hex}
                                    readOnly
                                    style={{
                                        background: color.hex,
                                        color: `rgb(${255 - color.rgb.r}, ${255 - color.rgb.g}, ${255 - color.rgb.b})`
                                    }}
                                />
                                <div className="picker_wrapper position-relative">
                                    <div className="position-absolute">
                                        {showPicker && <ColorPicker
                                            width={280} height={100}
                                            color={color} onChange={setColor} hideHSV hideRGB dark
                                        />}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex flex-stack pt-10'>
                            <div className='me-2'>
                            </div>

                            <div>
                                <button
                                    type='button'
                                    className='btn btn-lg btn-secondary me-3'
                                    onClick={() => setShowPage('list')}
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    className='btn btn-lg btn-primary me-3'
                                >
                                    {!loading && <span className='indicator-label'>
                                        Update
                                        <KTSVG
                                            path='/media/icons/duotune/arrows/arr064.svg'
                                            className='svg-icon-3 ms-2 me-0'
                                        />
                                    </span>}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}