import React, { useEffect } from 'react'
import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik'
import * as Yup from 'yup'

import { createAttendanceAutomation } from '../../../../modules/CRUD/CRUD';
import { KTSVG } from '../../../../../_library/helpers'
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../../setup';
import { DisciplineStepType } from '../../../../types/DisciplineStepType';

interface IAutomation {
    name: string,
    description: string,
    triggerPoints: number
    disciplineStepId: string
}

type Props = {
    setShowPage: (page: string) => void
    tenantId: string
    disciplineSteps: DisciplineStepType[]
}

export function CreateAttendanceAutomation({ setShowPage, tenantId, disciplineSteps }: Props) {
    const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
    const [loading, setLoading] = React.useState(false)
    // const [disciplineSteps, setDisciplineSteps] = React.useState<DisciplineStepType[]>([])

    const initValues: IAutomation = {
        name: '',
        description: '',
        triggerPoints: 0,
        disciplineStepId: '',
    }

    const automationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Automation name is required'),
        description: Yup.string()
            .required('Automation description is required'),
        triggerPoints: Yup.number()
            .positive(),
        disciplineStepId: Yup.string()
            .required('Discipline Step is required'),
    })

    const handleSubmit = (values: IAutomation, actions: FormikValues) => {
        setLoading(true)
        setTimeout(() => {
            createAttendanceAutomation(
                tenantId,
                values.name,
                values.description,
                values.triggerPoints,
                parseInt(values.disciplineStepId),
                token
            )
                .then(() => {
                    setShowPage('list')
                    setLoading(false)
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                })
        }, 1000)
    }

    useEffect(() => {
        let abort = new AbortController()

        return () => {
            abort.abort()
        }
    }, [])

    return (
        <div className='w-100'>
            <Formik
                validationSchema={automationSchema}
                initialValues={initValues}
                onSubmit={handleSubmit}
            >
                {({ submitForm, isValid, dirty, handleChange, values, getFieldProps }) => (
                    <Form className='form py-5 w-100' noValidate id='kt_modal_create_onboarding_form'>
                        <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                                <span className='required'>Automation Name</span>
                            </label>

                            <Field
                                type='text'
                                className='form-control form-control-lg form-control-solid'
                                name='name'
                                placeholder=''
                            />
                            <div className='text-danger'>
                                <ErrorMessage name='name' />
                            </div>
                        </div>

                        <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                                <span className='required'>Description</span>
                            </label>

                            <Field
                                type='text'
                                className='form-control form-control-lg form-control-solid'
                                name='description'
                                placeholder=''
                            />
                            <div className='text-danger'>
                                <ErrorMessage name='description' />
                            </div>
                        </div>

                        <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                                <span className='required'>Trigger Points</span>
                            </label>

                            <Field
                                type='number'
                                step={0.1}
                                className='form-control form-control-lg form-control-solid'
                                name='triggerPoints'
                                placeholder=''
                            />
                            <div className='text-danger'>
                                <ErrorMessage name='triggerPoints' />
                            </div>
                        </div>

                        <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                                <span className='required'>Discipline Step</span>
                            </label>

                            <Field
                                as='select'
                                name='disciplineStepId'
                                className='form-select form-select-solid'
                            >
                                <option value=""></option>
                                {
                                    disciplineSteps.map(step => (
                                        <option key={step.id} value={step.id}>{step.name}</option>
                                    ))
                                }
                            </Field>
                            <div className='text-danger'>
                                <ErrorMessage name='disciplineStepId' />
                            </div>
                        </div>

                        <div className='d-flex flex-stack pt-10'>
                            <div className='me-2'>
                            </div>

                            <div>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-secondary me-3'
                                    onClick={() => setShowPage('list')}
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    className='btn btn-sm btn-primary me-3'
                                >
                                    {!loading && <span className='indicator-label'>
                                        Add
                                        <KTSVG
                                            path='/media/icons/duotune/arrows/arr064.svg'
                                            className='svg-icon-3 ms-2 me-0'
                                        />
                                    </span>}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}