import clsx from 'clsx';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import * as Yup from 'yup';
import { toAbsoluteUrl } from '../../../_library/helpers';
import { PageLink, PageTitle } from '../../../_library/layout/core';
import { RootState } from '../../../setup';
import { UserModel, UserRoleEnum } from '../../models/UserModel';
import {
	getUser,
	inviteSuperadmin,
	inviteSupervisor,
	sendForgotPassword,
	setUserAvatar,
	updateUser,
} from '../../modules/CRUD/CRUD';

const editUserBreadCrumbs: Array<PageLink> = [
	{
		title: 'Users',
		path: '',
		isSeparator: false,
		isActive: false,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
];

type RouteParams = {
	userId: string
}

function EditUser() {
	const { userId } = useParams<RouteParams>();
	invariant(userId, 'user id falsy in this route');
	const navigate = useNavigate();
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const [loading, setLoading] = useState(false);
	const [avatarFile, setAvatarFile] = useState<any>(null);
	const [avatar, setAvatar] = useState('');
	const [user, setUser] = useState<UserModel | null>(null);
	const [initialValues, setInitialValues] = useState({
		firstName: '',
		lastName: '',
		email: '',
		isEmailVerified: false,
		isLockedOut: false,
		role: '',
	});

	const registrationSchema = Yup.object().shape({
		firstName: Yup.string()
									.min(3, 'Minimum 3 symbols')
									.max(50, 'Maximum 50 symbols')
									.required('First name is required'),
		lastName: Yup.string()
								 .min(3, 'Minimum 3 symbols')
								 .max(50, 'Maximum 50 symbols')
								 .required('Last name is required'),
		email: Yup.string(),
		role: Yup.string()
						 .required('Role is required'),
	});

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files[0]) {
			let reader = new FileReader();
			let file = e.target.files[0];
			setAvatarFile(file);
			reader.onloadend = () => {
				if (reader && reader.result) {
					setAvatar(reader.result as string);
				}
			};
			reader.readAsDataURL(file);
		}
	};

	const removeAvatar = () => {
		setAvatar('');
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema: registrationSchema,
		onSubmit: (values,
							 {
								 setStatus,
								 setSubmitting,
							 }) => {
			setLoading(true);
			setTimeout(() => {
				updateUser(
					parseInt(userId),
					values.firstName,
					values.lastName,
					values.email,
					values.isEmailVerified,
					values.isLockedOut,
					values.role.split(','),
					token,
				)
					.then(({ data }) => {
						if (avatarFile) {
							const requestBody = new FormData();
							requestBody.append('file', avatarFile);
							setUserAvatar(data.id, requestBody, token)
								.then(() => {
									setLoading(false);
									navigate('/users');
								})
								.catch(error => {
									console.log(error);
									setLoading(false);
									navigate('/users');
								});
						} else {
							setLoading(false);
							navigate('/users');
						}
					})
					.catch((err) => {
						console.log(err);
						toast.error(err.response.data.message || 'Error editing user')
						setLoading(false);
					});
			}, 1000);
		},
	});

	useEffect(() => {
		getUser(parseInt(userId), token)
			.then(({ data }) => {
				console.log(data);
				setUser(data);
				setInitialValues({
					firstName: data.firstName,
					lastName: data.lastName,
					email: data.email,
					isEmailVerified: data.isEmailVerified,
					isLockedOut: data.isLockedOut,
					role: data.roles.toString(),
				});
			});
	}, [userId, token]);

	const handleResendInvite = () => {
		if (user?.roles?.includes(UserRoleEnum.SuperAdmin)) {
			console.log('resending invite to superadmin', user?.email);
			inviteSuperadmin(user?.email, token)
				.then(res => {
					console.log('Invite re-sent to superadmin', user?.email);
				})
				.catch(err => {
					console.error(`Error from trying to resend invite to superadmin`, err);
				});
		} else if (user?.roles?.includes(UserRoleEnum.Supervisor)) {
			console.log('resending invite to superuser', user?.email);
			inviteSupervisor(user?.email, token)
				.then(res => {
					console.log('Invite re-sent to supervisor', user?.email);
				})
				.catch(err => {
					console.error(`Error from trying to resend invite to supervisor`, err);
				});
		} else {
			throw new Error('Only supporting supervisor/superadmin invites currently');
		}
	};

	const handleSendForgotPassword = () => {
		if (!user?.email) {
			throw new Error('Unable to send forgot password as no email exists');
		}
		return sendForgotPassword(user?.email, token)
			.then(res => {
				console.log('sent forgot password', res);
			})
			.catch(err => {
				console.error(`Error from trying to send forgot password link`, err);
			});
	};

	return (
		<>
			<PageTitle breadcrumbs={editUserBreadCrumbs}>Edit a User</PageTitle>
			<div className="card edit_user_card">
				<div className="card-header border-0 pt-5"></div>
				<div className="card-body py-3">
					<div className="col-lg-12 text-center">
						<div className="d-flex align-items-center justify-content-center rounded bg-white bg-body shadow w-150px h-150px m-auto">
							<div
								className={`image-input image-input-outline w-120px h-120px ${avatar === '' ? 'image-input-empty' : ''}`}
								data-kt-image-input="true"
								style={{ backgroundImage: `url(${user?.avatarUrl ? user.avatarUrl : toAbsoluteUrl('/media/avatars/blank.png')})` }}
							>
								<div
									className="image-input-wrapper no-shadow shadow-none"
									style={{ backgroundImage: `url(${toAbsoluteUrl(avatar)})` }}
								></div>
								<label
									className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
									data-kt-image-input-action="change"
									data-bs-toggle="tooltip"
									title=""
									data-bs-original-title="Change avatar">
									<i className="bi bi-pencil-fill fs-7"></i>
									<input
										type="file"
										name="avatar"
										accept=".png, .jpg, .jpeg"
										onChange={handleFileChange} />
									<input
										type="hidden"
										name="avatar_remove" />
								</label>
								<span
									className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
									data-kt-image-input-action="remove"
									data-bs-toggle="tooltip"
									title=""
									data-bs-original-title="Remove avatar"
									onClick={removeAvatar}
								>
									<i className="bi bi-x fs-2"></i>
								</span>
							</div>
						</div>
						<div className="form-text mt-5">Set the product thumbnail image. Only
																						*.png, *.jpg and *.jpeg image files
																						are accepted
						</div>
					</div>
					<div className="w-lg-700px p-lg-15 p-10 bg-white rounded mx-auto">
						<form
							className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
							noValidate
							id="kt_login_signup_form"
							onSubmit={formik.handleSubmit}

						>
							<div className="row fv-row mb-7">
								<div className="col-xl-6">
									<div className="fv-row">
										<label className="form-label fw-bolder text-dark fs-6">First Name</label>
										<input
											placeholder="First Name"
											type="text"
											autoComplete="off"
											{...formik.getFieldProps('firstName')}
											className={clsx(
												'form-control form-control-lg form-control-solid',
												{
													'is-invalid': formik.touched.firstName && formik.errors.firstName,
												},
												{
													'is-valid': formik.touched.firstName && !formik.errors.firstName,
												},
											)}
										/>
										{formik.touched.firstName && formik.errors.firstName && (
											<div className="fv-plugins-message-container">
												<div className="fv-help-block">
													<span role="alert">{formik.errors.firstName}</span>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className="col-xl-6">
									<div className="fv-row">
										<label className="form-label fw-bolder text-dark fs-6">Last Name</label>
										<input
											placeholder="Last Name"
											type="text"
											autoComplete="off"
											{...formik.getFieldProps('lastName')}
											className={clsx(
												'form-control form-control-lg form-control-solid',
												{
													'is-invalid': formik.touched.lastName && formik.errors.lastName,
												},
												{
													'is-valid': formik.touched.lastName && !formik.errors.lastName,
												},
											)}
										/>
										{formik.touched.lastName && formik.errors.lastName && (
											<div className="fv-plugins-message-container">
												<div className="fv-help-block">
													<span role="alert">{formik.errors.lastName}</span>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className="col-xl-6">
									<div className="fv-row">
										<label className="form-label fw-bolder text-dark fs-6">Email</label>
										<input
											placeholder="test.email@secchi.io"
											type="text"
											autoComplete="off"
											{...formik.getFieldProps('email')}
											className={clsx(
												'form-control form-control-lg form-control-solid',
												{
													'is-invalid': formik.touched.email && formik.errors.email,
												},
												{
													'is-valid': formik.touched.email && !formik.errors.email,
												},
											)}
										/>
										{!!user?.email && (
											<a href={`mailto:${user?.email}`}>{user?.email}</a>
										)}
										{formik.touched.email && formik.errors.email && (
											<div className="fv-plugins-message-container">
												<div className="fv-help-block">
													<span role="alert">{formik.errors.email}</span>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className="mt-5">
									<label className="fw-bolder text-dark fs-6 me-5">Email:</label>
									{!user?.email && <span>No email set</span>}

								</div>
							</div>

							<div className="fv-row mb-7">
								<div className="form-check form-check-custom form-check-solid">
									<input
										className="form-check-input"
										type="checkbox"
										id="flexCheckDefault"
										{...formik.getFieldProps('isEmailVerified')}
										checked={formik.values.isEmailVerified}
									/>
									<label
										className="form-check-label fw-bolder text-dark fs-6"
										htmlFor="flexCheckDefault">
										Verify Email
									</label>
								</div>
							</div>

							<div className="fv-row mb-7">
								<div className="form-check form-check-custom form-check-solid">
									<input
										className="form-check-input"
										type="checkbox"
										id="flexCheckDefault"
										{...formik.getFieldProps('isLockedOut')}
										checked={formik.values.isLockedOut}
									/>
									<label
										className="form-check-label fw-bolder text-dark fs-6"
										htmlFor="flexCheckDefault">
										Lock Out
									</label>
								</div>
							</div>

							<div className="fv-row mb-7">
								<label className="form-label fw-bolder text-dark fs-6">Role</label>
								<select
									{...formik.getFieldProps('role')}
									className={clsx(
										'form-control form-control-lg form-control-solid',
										// {
										//     'is-invalid': formik.touched.role && formik.errors.role,
										// },
										// {
										//     'is-valid': formik.touched.role && !formik.errors.role,
										// }
									)}
									style={{ appearance: 'auto' }}
								>
									<option value=""></option>
									<option value="SecchiAdmin">Secchi Admin</option>
									<option value="SuperAdmin">Super Admin</option>
									<option value="Supervisor">Supervisor</option>
									<option value="Provisioner">Provisioner</option>
									<option value="SuperAdmin,Provisioner">SuperAdmin & Provisioner</option>
								</select>
								{formik.touched.role && formik.errors.role && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">
											<span role="alert">{formik.errors.role}</span>
										</div>
									</div>
								)}
							</div>

							<div className="d-flex mt-10">
								{!user?.isEmailVerified && (
									<button
										type="button"
										className="btn btn-lg btn-secondary me-auto"
										onClick={handleResendInvite}
									>
										Resend Invite
									</button>
								)}
								{user?.isEmailVerified && (
									<button
										type="button"
										className="btn btn-lg btn-secondary me-auto"
										onClick={handleSendForgotPassword}
									>
										Send forgot password
									</button>
								)}
								<button
									type="submit"
									className="btn btn-lg btn-primary ms-auto"
									disabled={formik.isSubmitting || !formik.isValid}
								>
									{!loading && <span className="indicator-label">Update</span>}
									{loading && (
										<span
											className="indicator-progress"
											style={{ display: 'block' }}
										>
											Please wait...{' '}
											<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
										</span>
									)}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}

export { EditUser };
