import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { SkeDialogDelete } from '../../../../common/components/dialog-delete';
import { SkeDialogTextPrompt } from '../../../../common/components/dialog-text-prompt';
import { RootState } from '../../../../setup';
import {
	createRecognitionCategory,
	getRecognitionCategories,
	removeRecognitionCategory,
	updateRecognitionCategory,
} from '../../../modules/CRUD/CRUD';
import './styles/Recognition.scss';

interface CategoryType {
	id: number,
	name: string
}

export function RecognitionEdit() {
	const { companyId } = useParams();
	invariant(companyId, 'Company id falsy in this route');
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const [categories, setCategories] = useState<CategoryType[]>([]);
	const [selectedCategory, setSelectedCategory] = useState<CategoryType>();
	const [showCategoryNameDialog, setShowCategoryNameDialog] = useState<boolean>(false);
	const [showDeleteCategoryDialog, setShowDeleteCategoryDialog] = useState<boolean>(false);
	const [categoryNameDialogType, setCategoryNameDialogType] = useState<'add' | 'edit'>();

	const refreshData = (): Promise<void> => {
		return getRecognitionCategories(companyId, token)
			.then(({ data }) => {
				setCategories(data.items);
			})
			.catch(err => {
				console.error(err);
				toast.error('Error loading categories');
			});
	};

	const closeAndResetDialogs = () => {
		setShowCategoryNameDialog(false);
		setShowDeleteCategoryDialog(false);
		setSelectedCategory(undefined);
		setCategoryNameDialogType(undefined);
	};

	const handleAddCategory = (value: string) => {
		createRecognitionCategory(companyId, value, token)
			.then(() => {
				refreshData();
				closeAndResetDialogs();
			})
			.catch(err => {
				console.error(err.response);
				toast.error('Error adding category');
			});
	};

	const handleEditCategory = (value: string) => {
		invariant(selectedCategory, `Can't proceed with edit of category as it's undefined`);
		if (value !== '') {
			updateRecognitionCategory(selectedCategory.id, value, token)
				.then(() => {
					refreshData();
					closeAndResetDialogs();
				})
				.catch(err => {
					console.error(err.response);
					toast.error('Error updating category');
				});
		}
	};

	const handleConfirmDelete = () => {
		invariant(selectedCategory, `Can't proceed with deletion of category as it's undefined`);
		removeRecognitionCategory(selectedCategory.id, token)
			.then(() => {
				refreshData();
				closeAndResetDialogs();
			})
			.catch(err => {
				console.error(err.response);
				toast.error('Error deleting category');
			});
	};

	useEffect(() => {
		getRecognitionCategories(companyId, token)
			.then(({ data }) => {
				setCategories(data.items);
			})
			.catch(err => {
				console.log(err.response);
			});
	}, [companyId, setCategories, token]);

	return (
		<>
			<div className="card coaching_settings_container">
				<div
					className="card-header border-0 cursor-pointer"
					role="button"
				>
					<div className="card-title m-0">
						<h3 className="fw-bolder m-0">Recognition Categories</h3>
					</div>
				</div>
				<div className="card-body border-top">
					<div className="text-start">
						<button
							type="button"
							className="link-primary btn btn_add mt-2 px-0"
							onClick={() => {
								setCategoryNameDialogType('add');
								setShowCategoryNameDialog(true);
							}}>+ Add New
						</button>
					</div>
					<div className="mb-0 check_wrapper">
						{categories.length > 0 && <div className="table-responsive">
							<table className="table table-striped">
								<thead>
									<tr>
										<th className="text-muted fw-bold min-w-300px">Name</th>
										<th className="text-muted fw-bold w-200px"></th>
									</tr>
								</thead>
								<tbody>
									{categories.map((category, index) => {
										return <tr
											className="action_line"
											key={index}>
											<td className="action_name">{category.name}</td>
											<td className="action_buttons">
												<span
													className="btn_remove"
													onClick={() => {
														setCategoryNameDialogType('edit');
														setSelectedCategory(category);
														setShowCategoryNameDialog(true);
													}}
												>
														<i className="fas fa-edit text-primary fs-4"></i>
												</span>
												<span
													className="btn_remove ms-3"
													onClick={() => {
														setSelectedCategory(category);
														setShowDeleteCategoryDialog(true);
													}}
												>
														<i className="fas fa-trash text-danger fs-4"></i>
												</span>
											</td>
										</tr>;
									})}
								</tbody>
							</table>
						</div>}
					</div>

				</div>
			</div>

			{showCategoryNameDialog && (
				<SkeDialogTextPrompt
					title={`${categoryNameDialogType === 'add' ? 'New' : 'Edit'} Recognition Category`}
					initialValue={categoryNameDialogType === 'add' ? '' : selectedCategory?.name}
					onConfirm={(value: string) => {
						if (categoryNameDialogType === 'add') {
							return handleAddCategory(value);
						}
						return handleEditCategory(value);
					}}
					onCancel={closeAndResetDialogs}
				/>
			)}
			{showDeleteCategoryDialog && (
				<SkeDialogDelete
					onCancel={closeAndResetDialogs}
					onConfirm={handleConfirmDelete}
					successMessage="Category deleted"
					message="Are you sure you want to delete this category?"
				/>
			)}
		</>
	);
}
