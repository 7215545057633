import { useState } from 'react';
import { isEmail, ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { KTSVG } from '../../../../_library/helpers';
import './styles/invite_supervisor.scss';
import { toast } from 'react-toastify';

type Props = {
	companyId: string,
	goNext: () => void
	goPrev: () => void
}

export function InviteSupervisorStep({
																			 companyId,
																			 goNext,
																			 goPrev,
																		 }: Props) {
	const [emails, setEmails] = useState<string[]>([]);
	const [inviting, setInviting] = useState(false);

	const emailsLimit = 25;

	const handleInvite = () => {
		setInviting(true);
		setTimeout(async () => {
			try {
				for (let i = 0; i < emails.length; i++) {
					// await sendSupervisorInvite(companyId, emails[i], token)
				}

				toast.success('Invite(s) successfully sent');
			} catch (error: any) {
				console.log(error.response);
				toast.error(error?.response?.data?.message || 'Error inviting supervisor(s)');
			}
			setInviting(false);
		}, 1000);
	};

	return (
		<>
			<ReactMultiEmail
				placeholder="Type email"
				emails={emails}
				onChange={(items) => {
					if (items.length < emailsLimit + 1) {
						setEmails(items);
					}
				}}
				validateEmail={email => {
					return isEmail(email) && (document.querySelectorAll('.react-multi-email > .email_item').length < emailsLimit); // return boolean
				}}
				getLabel={(
					email,
					index,
					removeEmail,
				) => {
					return (
						<div
							data-tag
							key={index}
							className="email_item">
							<span>{email}</span>
							<span
								data-tag-handle
								onClick={() => removeEmail(index)}>
									×
							</span>
						</div>
					);
				}}
			/>
			<div className="invite_footer mt-5">
				<span>Invite <b>{emails.length}Guest</b> to secchi</span>
				<button
					className="btn btn-primary"
					type="button"
					onClick={handleInvite}>
					{!inviting && <span>Invite</span>}
					{inviting && (
						<span
							className="indicator-progress poppins_medium"
							style={{ display: 'block' }}>
								Please wait...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
						</span>
					)}
				</button>
			</div>

			<div className="d-flex flex-stack pt-10 mt-20">
				<div className="me-2">
					<button
						onClick={goPrev}
						type="button"
						className="btn btn-lg btn-light-primary me-3"
					>
						<KTSVG
							path="/media/icons/duotune/arrows/arr063.svg"
							className="svg-icon-4 me-1"
						/>
						Previous
					</button>
				</div>

				<div>
					<button
						type="button"
						className="btn btn-lg btn-primary me-3"
						onClick={goNext}
					>
						<span className="indicator-label">
								Complete
								<KTSVG
									path="/media/icons/duotune/arrows/arr064.svg"
									className="svg-icon-3 ms-2 me-0"
								/>
						</span>
					</button>
				</div>
			</div>
		</>
	);
}
