import React from 'react'
import {MenuItem} from './MenuItem'
// import {MenuInnerWithSub} from './MenuInnerWithSub'
// import {MegaMenu} from './MegaMenu'
// import {useIntl} from 'react-intl'

export function MenuInner() {
  // const intl = useIntl()
  return (
    <>
      {/* <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' /> */}
      <MenuItem title={'Users'} to='/users' />
      <MenuItem title='Companies' to='/companies' />
      <MenuItem title='Templates' to='/templates' />
    </>
  )
}
