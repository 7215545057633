import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { SkeDialogDelete } from '../../../../../common/components/dialog-delete';
import { RootState } from '../../../../../setup';
import { getAttendanceReasons, removeAttendanceReason } from '../../../../modules/CRUD/CRUD';
import { ReasonType } from '../../../../types/ReasonType';

type Props = {
	tenantId: string
	setShowPage: (page: string) => void
	setEditReason: (reason: ReasonType) => void
}

export default function ReasonsList({
																			tenantId,
																			setShowPage,
																			setEditReason,
																		}: Props) {
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const [reasons, setReasons] = useState<ReasonType[]>([]);
	const [selectedReason, setSelectedReason] = useState<ReasonType>();
	const [showDeleteReasonDialog, setShowDeleteReasonDialog] = useState<boolean>(false);

	const refreshData = (): Promise<void> => {
		return getAttendanceReasons(tenantId, false, token)
			.then(({ data }) => {
				setReasons(data.items);
			})
			.catch(err => console.log(err));
	};

	const closeAndResetDialogs = () => {
		setShowDeleteReasonDialog(false);
		setSelectedReason(undefined);
	};

	const handleEditReason = (reason: ReasonType) => {
		setEditReason(reason);
		setShowPage('edit');
	};

	const handleConfirmDelete = () => {
		invariant(selectedReason, `Can't proceed with deletion of reason as it's undefined`);
		removeAttendanceReason(selectedReason.id, token)
			.then(() => {
				refreshData();
				closeAndResetDialogs();
			})
			.catch(err => {
				console.error(err);
				toast.error('Error deleting reason');
			});
	};

	useEffect(() => {
		let abortController = new AbortController();

		if (!!tenantId) {
			refreshData();
		}

		return () => {
			abortController.abort();
		};
	}, [tenantId, token]);

	return (
		<>
			<div>
				<div className="d-flex">
					<button
						type="button"
						className="link-primary btn btn_add mt-2 px-0"
						onClick={() => setShowPage('create')}
					>
						+ Add New Attendance Reason
					</button>
				</div>
				<table className="table table-striped">
					<thead>
						<tr>
							<th className="fw-bolder">Content</th>
							<th className="fw-bolder">isExcused</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{reasons.map((reason, index) => {
							return <tr
								className="action_line"
								key={index}>
								<td className="action_name">{reason.content}</td>
								<td className="action_name">{reason.isExcused ? 'Excused' : 'Unexcused'}</td>
								<td className="action_buttons">
								<span
									className="btn_edit ms-3"
									onClick={() => handleEditReason(reason)}
								>
									<i className="fas fa-edit text-primary fs-4"></i>
                </span>
									<span
										className="btn_remove ms-3"
										onClick={() => {
											setSelectedReason(reason);
											setShowDeleteReasonDialog(true);
										}}
									>
									<i className="fas fa-trash text-danger fs-4"></i>
								</span>
								</td>
							</tr>;
						})}
					</tbody>
				</table>
			</div>
			{showDeleteReasonDialog && selectedReason && (
				<SkeDialogDelete
					onCancel={closeAndResetDialogs}
					onConfirm={handleConfirmDelete}
					successMessage="Reason deleted"
					message={`Are you sure to delete ${selectedReason.content}`}
				/>
			)}
		</>
	);
}
