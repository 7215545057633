import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

interface Props {
	message?: string;
	title?: string;
	cancelLabel?: string;
	confirmLabel?: string;
	onCancel?: () => void;
	onConfirm?: (toast: Function) => void;
	successMessage?: string;
}

export function SkeDialogDelete({
																	title = 'Confirm Delete',
																	message = 'Are you sure you want to delete this?',
																	cancelLabel = 'Cancel',
																	confirmLabel = 'Confirm',
																	onCancel,
																	onConfirm,
																	successMessage = 'Item successfully deleted',
																}: Props) {
	const [show, setShow] = useState(true);

	const handleClose = () => {
		setShow(false);
		onCancel && onCancel();
	};

	const successToast = () => {
		toast.success(successMessage || 'Item successfully deleted!', {
			position: 'top-right',
			theme: 'colored',
			autoClose: 2000,
		});
	}

	const handleConfirm = () => {
		setShow(false);
		if (onConfirm) {
			onConfirm(successToast);
		}
	};

	return (
		<Modal
			show={show}
			onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{message}</Modal.Body>
			<Modal.Footer>
				<Button
					variant="secondary"
					onClick={handleClose}>
					{cancelLabel}
				</Button>
				<Button
					variant="danger"
					onClick={handleConfirm}>
					{confirmLabel}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
