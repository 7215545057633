import React from 'react'
import { DisciplineStepType } from '../../../types/DisciplineStepType'
import { DisciplineStepsList } from './discipline_steps/DisciplineStepsList'
import { CreateDisciplineStep } from './discipline_steps/CreateDisciplineStep'
import { EditDisciplineStep } from './discipline_steps/EditDisciplineStep'

type Props = {
    companyId: string,
    goNext: () => void
}

export function DisciplineStep({ companyId, goNext }: Props) {
    const [editStep, setEditStep] = React.useState<DisciplineStepType | null>(null)
    const [showPage, setShowPage] = React.useState('list')

    return (
        <div className="step_wrapper_3 min-h-300px">
            {showPage === 'list' && <DisciplineStepsList goNext={goNext} setShowPage={setShowPage} tenantId={companyId} setEditStep={setEditStep} />}
            {showPage === 'create' && <CreateDisciplineStep setShowPage={setShowPage} tenantId={companyId} />}
            {showPage === 'edit' && !!editStep && <EditDisciplineStep setShowPage={setShowPage} editStep={editStep} />}
        </div>
    )
}