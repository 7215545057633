import React, { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../../setup'
import { getDisciplineStepsByType } from '../../../../modules/CRUD/CRUD'
import { AttendanceAutomationType } from '../../../../types/AttendanceAutomationType'
import { DisciplineStepType } from '../../../../types/DisciplineStepType'
import AttendanceAutomationsList from './AttendanceAutomationsList'
import { CreateAttendanceAutomation } from './CreateAttendanceAutomation'
import { EditAttendanceAutomation } from './EditAttendanceAutomation'

type Props = {
    tenantId: string
}

export default function AttendanceAutomations({ tenantId}: Props) {
    const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
    const [showPage, setShowPage] = React.useState('list')
    const [editAutomation, setEditAutomation] = React.useState<AttendanceAutomationType | null>(null)
    const [disciplineSteps, setDisciplineSteps] = React.useState<DisciplineStepType[]>([])
    
    useEffect(() => {
        let abort = new AbortController()

        getDisciplineStepsByType(tenantId, 'Attendance', false, token)
            .then(({ data }) => setDisciplineSteps(data.items))
            .catch(err => console.log(err))

        return () => {
            abort.abort()
        }
    }, [tenantId, token])

    return (
        <>
            {showPage === 'list' && <>
                <div className='min-h-300px'>
                    <AttendanceAutomationsList tenantId={tenantId} setShowPage={setShowPage} setEditAutomation={setEditAutomation} />
                </div>
            </>}
            {showPage === 'create' && <CreateAttendanceAutomation tenantId={tenantId} setShowPage={setShowPage} disciplineSteps={disciplineSteps} />}
            {showPage === 'edit' && !!editAutomation && <EditAttendanceAutomation setShowPage={setShowPage} editAutomation={editAutomation} disciplineSteps={disciplineSteps} />}
        </>
    )
}