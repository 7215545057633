/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

type Props = {
    companyId: string
}

const EditCompanyHeader: React.FC<Props> = ({ companyId }) => {
    const location = useLocation()

    return (
        <div className='card mb-5 mb-xl-10 account_header_wrapper'>
            <div className='card-body pt-0 pb-0'>
                <div className='d-flex overflow-auto h-55px justify-content-center'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === `/edit-company/${companyId}/profile` && 'active')
                                }
                                to={`/edit-company/${companyId}/profile`}
                            >
                                Profile
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === `/edit-company/${companyId}/disciplines` && 'active')
                                }
                                to={`/edit-company/${companyId}/disciplines`}
                            >
                                Disciplines
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === `/edit-company/${companyId}/attendances` && 'active')
                                }
                                to={`/edit-company/${companyId}/attendances`}
                            >
                                Attendances
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === `/edit-company/${companyId}/coaching` && 'active')
                                }
                                to={`/edit-company/${companyId}/coaching`}
                            >
                                Coaching
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === `/edit-company/${companyId}/recognition` && 'active')
                                }
                                to={`/edit-company/${companyId}/recognition`}
                            >
                                Recognition
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export { EditCompanyHeader }
