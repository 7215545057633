import { CategorySubtypeEnum } from '../../category/models/category.model';

export interface TemplateModel {
	id: number;
	tenant_id: string;
	// TODO: CompanyGroup update to be a string
	group_id: null;
	type: TemplateTypeEnum;
	category_id: number;
	// category
	name: string;
	description: string | null;
	template: TemplateData;
	updated_at: string;
	created_at: string;
	deleted_at: string | null;
}

export enum TemplateTypeEnum {
	Discipline = 'DISCIPLINE',
	Coaching = 'COACHING',
	Attendance = 'ATTENDANCE',
	Recognition = 'RECOGNITION',
}

export interface TemplateData {
	fields: {
		incidentDetails?: string;
		consequences?: string;
		expectations?: string;
	};
}

// TODO: rename with replication, and fix types
export interface TemplateDuplicationPayloadAllOptions {
	templateIds: number[];
	tenant_id: string;
	createBlankTemplate: boolean;
	// xor below
	category_id: number;
	// group
	category_name: string;
	category_subtype: CategorySubtypeEnum;
}

export interface TemplateReplicationWithExistingCategory extends Omit<TemplateDuplicationPayloadAllOptions, 'category_name' | 'category_subtype'> {
	category_id: number;
	// category_name: never;
	// category_subtype: never;
}

export interface TemplateReplicationWithNewCategory extends Omit<TemplateDuplicationPayloadAllOptions, 'category_id'> {
	// category_id: never;
	category_name: string;
	category_subtype: CategorySubtypeEnum;
}

export interface TemplateCreateBase {
	name: string;
	description: string | null;
	category_id?: number;
	category_name?: string;
	category_subtype?: CategorySubtypeEnum;
	template: TemplateData;
	tenant_id: string;
	type: TemplateTypeEnum;
}

export interface TemplateCreateWithExistingCategory extends TemplateCreateBase {
	category_id: number;
}

export interface TemplateCreateWithNewCategory extends TemplateCreateBase {
	category_name: string;
	category_subtype: CategorySubtypeEnum;
}
