import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

interface Props {
	title?: string;
	message?: string;
	cancelLabel?: string;
	confirmLabel?: string;
	variant?: 'input' | 'textarea';
	onCancel?: () => void;
	onConfirm?: (input: string) => void;
	onSuccessToast?: () => void;
	onFailToast?: () => void;
	initialValue?: string;
}

export function SkeDialogTextPrompt({
																			title,
																			message,
																			cancelLabel = 'Cancel',
																			confirmLabel = 'Confirm',
																			variant = 'input',
																			onCancel,
																			onConfirm,
																			onSuccessToast,
																			onFailToast,
																			initialValue,
																		}: Props) {
	const [show, setShow] = useState(true);
	const [inputValue, setInputValue] = useState(initialValue || '');

	const handleClose = () => {
		setShow(false);
		onCancel && onCancel();
	};

	const handleConfirm = () => {
		setShow(false);
		if (onConfirm) {
			onConfirm(inputValue);
			if (onSuccessToast) {
				onSuccessToast();
			}
		} else {
			if (onFailToast) {
				onFailToast();
			}
		}
	};


	return (
		<Modal
			show={show}
			onHide={handleClose}>
			{title && (
				<Modal.Header closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
			)}
			<Modal.Body>
				<Form.Label>{message}</Form.Label>
				<Form.Control
					as={variant === 'textarea' ? 'textarea' : undefined}
					aria-label="Text Input"
					value={inputValue}
					onChange={(e) => setInputValue(e.target.value)}
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="secondary"
					onClick={handleClose}>
					{cancelLabel}
				</Button>
				<Button
					variant="primary"
					onClick={handleConfirm}>
					{confirmLabel}
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
