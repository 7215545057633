import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { SkeDialogDelete } from '../../../../../common/components/dialog-delete';
import { RootState } from '../../../../../setup';
import { getAttendanceRules, removeAttendanceRule } from '../../../../modules/CRUD/CRUD';
import { AttendanceRuleType } from '../../../../types/AttendanceRuleType';

type Props = {
	tenantId: string
	setShowPage: (page: string) => void
	setEditRule: (reason: AttendanceRuleType) => void
}

export default function RulesList({
																		tenantId,
																		setShowPage,
																		setEditRule,
																	}: Props) {
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const [rules, setRules] = useState<AttendanceRuleType[]>([]);
	const [selectedRule, setSelectedRule] = useState<AttendanceRuleType>();
	const [showDeleteRuleDialog, setShowDeleteRuleDialog] = useState<boolean>(false);

	const refreshData = (): Promise<void> => {
		return getAttendanceRules(tenantId, false, token)
			.then(({ data }) => {
				setRules(data.items);
			})
			.catch(err => {
				console.error(err);
				toast.error('Error loading rules');
			});
	};

	const closeAndResetDialogs = () => {
		setShowDeleteRuleDialog(false);
		setSelectedRule(undefined);
	};

	const handleEditReason = (rule: AttendanceRuleType) => {
		setEditRule(rule);
		setShowPage('edit');
	};

	const handleConfirmDelete = () => {
		invariant(selectedRule, `Can't proceed with deletion of rule as it's undefined`);
		removeAttendanceRule(selectedRule.id, token)
			.then(() => {
				refreshData();
				closeAndResetDialogs();
			})
			.catch(err => {
				console.error(err);
				toast.error('Error deleting rule');
			});
	};

	useEffect(() => {
		let abortController = new AbortController();

		if (!!tenantId) {
			getAttendanceRules(tenantId, false, token)
				.then(({ data }) => {
					setRules(data.items);
				})
				.catch(error => console.log(error));
		}

		return () => {
			abortController.abort();
		};
	}, [tenantId, token]);

	return (
		<>
			<div>
				<div className="d-flex">
					<button
						type="button"
						className="link-primary btn btn_add mt-2 px-0"
						onClick={() => setShowPage('create')}
					>
						+ Add New Attendance Rule
					</button>
				</div>
				<table className="table table-striped">
					<thead>
						<tr>
							<th className="fw-bolder">Name</th>
							<th className="fw-bolder">Occurrence</th>
							<th className="fw-bolder">Points To Assign</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{rules.map((rule, index) => {
							return <tr
								className="action_line"
								key={index}>
								<td className="action_name">{rule.name}</td>
								<td className="action_name">{rule.occurrence}</td>
								<td className="action_name">{rule.pointsToAssign}</td>
								<td className="action_buttons">
									<span
										className="btn_edit ms-3"
										onClick={() => handleEditReason(rule)}
									>
										<i className="fas fa-edit text-primary fs-4"></i>
									</span>
									<span
										className="btn_remove ms-3"
										onClick={() => {
											setSelectedRule(rule);
											setShowDeleteRuleDialog(true);
										}}
									>
										<i className="fas fa-trash text-danger fs-4"></i>
									</span>
								</td>
							</tr>;
						})}
					</tbody>
				</table>
			</div>
			{showDeleteRuleDialog && selectedRule && (
				<SkeDialogDelete
					onCancel={closeAndResetDialogs}
					onConfirm={handleConfirmDelete}
					successMessage="Rule deleted"
					message={`Are you sure to delete ${selectedRule.name}`}
				/>
			)}
		</>
	);
}
