import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { KTSVG } from '../../../../../_library/helpers';
import { SkeDialogDelete } from '../../../../../common/components/dialog-delete';
import { RootState } from '../../../../../setup';
import { getDisciplineSteps, removeDisciplineStep } from '../../../../modules/CRUD/CRUD';
import { DisciplineStepType } from '../../../../types/DisciplineStepType';

type Props = {
	goNext?: () => void
	setShowPage: (page: string) => void
	tenantId: string
	setEditStep: (dStep: DisciplineStepType) => void
}

export function DisciplineStepsList({
																			goNext,
																			setShowPage,
																			tenantId,
																			setEditStep,
																		}: Props) {
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const [disciplineSteps, setDisciplineSteps] = useState<DisciplineStepType[]>([]);
	const [selectedDisciplineStep, setSelectedDisciplineStep] = useState<DisciplineStepType>();
	const [showDeleteDisciplineStep, setShowDeleteDisciplineStep] = useState<boolean>(false);

	const refreshData = (): Promise<void> => {
		return getDisciplineSteps(tenantId, false, token)
			.then(({ data }) => {
				setDisciplineSteps(data.items);
			})
			.catch(err => {
				console.error(err);
				toast.error('Error loading discipline steps');
			});
	};

	const closeAndResetDialogs = () => {
		setShowDeleteDisciplineStep(false);
		setSelectedDisciplineStep(undefined);
	};

	const handleEditStep = (step: DisciplineStepType) => {
		setEditStep(step);
		setShowPage('edit');
	};

	const handleConfirmDelete = () => {
		invariant(selectedDisciplineStep, `Can't proceed with deletion of rule as it's undefined`);
		removeDisciplineStep(selectedDisciplineStep.id, token)
			.then(() => {
				refreshData();
				closeAndResetDialogs();
			})
			.catch(err => {
				console.error(err);
				toast.error('Error deleting discipline step');
			});
	};

	useEffect(() => {
		let abortController = new AbortController();

		if (!!tenantId) {
			refreshData();
		}

		return () => {
			abortController.abort();
		};
	}, [tenantId, token]);

	return (
		<>
			<div>
				{goNext && <div className="d-flex flex-stack mb-5">
					<div>
						<button
							type="button"
							className="btn btn-md btn-primary me-3"
							onClick={goNext}
						>
							<span className="indicator-label">
									Next
									<KTSVG
										path="/media/icons/duotune/arrows/arr064.svg"
										className="svg-icon-3 ms-2 me-0"
									/>
							</span>
						</button>
					</div>
				</div>}
				<div className="d-flex">
					<button
						type="button"
						className="link-primary btn btn_add mt-2 px-0"
						onClick={() => setShowPage('create')}
					>
						+ Add New Discipline Step
					</button>
				</div>
				<table className="table table-striped">
					<thead>
						<tr>
							<th className="fw-bolder">Name</th>
							<th className="fw-bolder">Type</th>
							<th className="fw-bolder">Number</th>
							<th className="fw-bolder">Color</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{disciplineSteps.map((step, index) => {
							return <tr
								className="action_line"
								key={index}>
								<td className="action_name">{step.name}</td>
								<td className="action_name">
									<span className={step.type === 'Attendance' ? 'badge badge-danger' : 'badge badge-info'}>{step.type}</span>
								</td>
								<td className="action_name">{step.stepNumber}</td>
								<td className="action_name d-flex align-items-center">
									<span
										className="me-1"
										style={{
											backgroundColor: step.stepColor,
											width: '20px',
											height: '20px',
											display: 'block',
										}}></span>{step.stepColor}
								</td>
								<td className="action_buttons">
									<span
										className="btn_edit ms-3"
										onClick={() => handleEditStep(step)}
									>
										<i className="fas fa-edit text-primary fs-4"></i>
									</span>
									<span
										className="btn_remove ms-3"
										onClick={() => {
											setSelectedDisciplineStep(step);
											setShowDeleteDisciplineStep(true);
										}}
									>
										<i className="fas fa-trash text-danger fs-4"></i>
									</span>
								</td>
							</tr>;
						})}
					</tbody>
				</table>
			</div>
			{showDeleteDisciplineStep && selectedDisciplineStep && (
				<SkeDialogDelete
					onCancel={closeAndResetDialogs}
					onConfirm={handleConfirmDelete}
					successMessage="Discipline Step deleted"
					message={`Are you sure to delete ${selectedDisciplineStep.name}`}
				/>
			)}
		</>
	);
}
