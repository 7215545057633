import { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { KTSVG } from '../../../../_library/helpers';
import { SkeDialogDelete } from '../../../../common/components/dialog-delete';
import { SkeDialogTextPrompt } from '../../../../common/components/dialog-text-prompt';
import { RootState } from '../../../../setup';
import {
	createCoachingCategory,
	getCoachingCategories,
	removeCoachingCategory,
	updateCoachingCategory,
} from '../../../modules/CRUD/CRUD';

type CategoryType = {
	id: number
	tenant_id: string
	name: string
}

type Props = {
	tenantId: string
	goPrev: () => void
	goNext: () => void
}

export default function CoachingStep({
																			 tenantId,
																			 goNext,
																			 goPrev,
																		 }: Props) {
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const [categories, setCategories] = useState<CategoryType[]>([]);
	const [selectedCategory, setSelectedCategory] = useState<CategoryType>();
	const [showCategoryNameDialog, setShowCategoryNameDialog] = useState<boolean>(false);
	const [showDeleteCoachingCategory, setShowDeleteCoachingCategory] = useState<boolean>(false);
	const [categoryNameDialogType, setCategoryNameDialogType] = useState<'add' | 'edit'>();

	const refreshData = (): Promise<void> => {
		return getCoachingCategories(tenantId, token)
			.then(({ data }) => {
				setCategories(data.items);
			})
			.catch(err => {
				console.error(err);
				toast.error('Error loading categories');
			});
	};

	const closeAndResetDialogs = () => {
		setShowCategoryNameDialog(false);
		setShowDeleteCoachingCategory(false);
		setSelectedCategory(undefined);
		setCategoryNameDialogType(undefined);
	};

	const handleAddCategory = (value: string) => {
		if (value !== '') {
			createCoachingCategory(tenantId, value, token)
				.then(() => {
						refreshData();
						closeAndResetDialogs();
				})
				.catch(err => {
					console.error(err.response);
					toast.error('Error adding category');
				});
		}
	};

	const handleEditCategory = (value: string) => {
		invariant(selectedCategory, `Can't proceed with edit of category as it's undefined`);
		if (value !== '') {
			updateCoachingCategory(selectedCategory.id, value, token)
				.then(() => {
					refreshData();
					closeAndResetDialogs();
				})
				.catch(err => {
					console.error(err.response);
					toast.error('Error updating category');
				});
		}
	};

	const handleConfirmDelete = () => {
		invariant(selectedCategory, `Can't proceed with deletion of category as it's undefined`);
		removeCoachingCategory(selectedCategory.id, token)
			.then(() => {
				refreshData();
				closeAndResetDialogs();
			})
			.catch(err => {
				console.error(err.response);
				toast.error('Error deleting category');
			});
	};

	useEffect(() => {
		let abortController = new AbortController();

		if (!!tenantId) {
			refreshData();
		}

		return () => {
			abortController.abort();
		};
	}, [tenantId, token]);

	return (
		<>
			<div>
				<div className="d-flex flex-stack mb-5">
					<div className="me-2">
						<button
							onClick={goPrev}
							type="button"
							className="btn btn-lg btn-light-primary me-3"
						>
							<KTSVG
								path="/media/icons/duotune/arrows/arr063.svg"
								className="svg-icon-4 me-1"
							/>
							Previous
						</button>
					</div>

					<div>
						<button
							type="button"
							className="btn btn-md btn-primary me-3"
							onClick={goNext}
						>
							<span className="indicator-label">
								Next
								<KTSVG
									path="/media/icons/duotune/arrows/arr064.svg"
									className="svg-icon-3 ms-2 me-0"
								/>
							</span>
						</button>
					</div>
				</div>
				<div className="d-flex">
					<button
						type="button"
						className="link-primary btn btn_add mt-2 px-0"
						onClick={() => {
							setCategoryNameDialogType('add');
							setShowCategoryNameDialog(true);
						}}
					>
						+ Add New Coaching Category
					</button>
				</div>
				<table className="table table-striped">
					<thead>
						<tr>
							<th className="fw-bolder">Name</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{categories.map((category, index) => {
							return <tr
								className="action_line"
								key={index}>
								<td className="action_name">{category.name}</td>
								<td className="action_buttons">
									<span
										className="btn_edit ms-3"
										onClick={() => {
											setSelectedCategory(category);
											setCategoryNameDialogType('edit');
											setShowCategoryNameDialog(true);
										}}
									>
											<i className="fas fa-edit text-primary fs-4"></i>
									</span>
									<span
										className="btn_remove ms-3"
										onClick={() => {
											setSelectedCategory(category);
											setShowDeleteCoachingCategory(true);
										}}
									>
											<i className="fas fa-trash text-danger fs-4"></i>
									</span>
								</td>
							</tr>;
						})}
					</tbody>
				</table>
			</div>

			{showCategoryNameDialog && (
				<SkeDialogTextPrompt
					title={`${categoryNameDialogType === 'add' ? 'New' : 'Edit'} Coaching Category`}
					initialValue={categoryNameDialogType === 'add' ? '' : selectedCategory?.name}
					onConfirm={(value: string) => {
						if (categoryNameDialogType === 'add') {
							return handleAddCategory(value);
						}
						return handleEditCategory(value);
					}}
					onCancel={closeAndResetDialogs}
				/>
			)}
			{showDeleteCoachingCategory && (
				<SkeDialogDelete
					onCancel={closeAndResetDialogs}
					onConfirm={handleConfirmDelete}
					successMessage="Category deleted"
					message="Are you sure you want to delete this category?"
				/>
			)}
		</>
	);
}
