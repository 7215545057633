import React, { FC, useEffect, useRef, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from '../../../setup'
import { SearchComponent } from '../../../_library/assets/ts/components'
import { KTSVG, toAbsoluteUrl } from '../../../_library/helpers'
// import { Search } from '../../../_library/partials'
import { UserModel } from '../../models/UserModel'
import { getUsersFromUrl } from '../../modules/CRUD/CRUD'

type Props = {
  setSuperadminId: (id: number) => void
}

const SuperadminSearch: FC<Props> = ({ setSuperadminId }) => {
  const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string
  const [superadmins, setSuperadmins] = useState<UserModel[]>([])
  // const [menuState, setMenuState] = useState<'main' | 'advanced' | 'preferences'>('main')
  const element = useRef<HTMLDivElement | null>(null)
  const wrapperElement = useRef<HTMLDivElement | null>(null)
  const resultsElement = useRef<HTMLDivElement | null>(null)
  // const suggestionsElement = useRef<HTMLDivElement | null>(null)
  const emptyElement = useRef<HTMLDivElement | null>(null)
  const selectedInputRef = useRef<HTMLInputElement>(null)

  const handleItemClick = (superadminId: number, email: string) => {
    setSuperadminId(superadminId)
    if (element.current) {
      const search: SearchComponent = SearchComponent.getInstance(element.current)
      search.hide()
    }
    if (selectedInputRef.current) {
      selectedInputRef.current.value = email
    }
  }

  useEffect(() => {
    // Initialize search handler
    const searchObject = SearchComponent.createInsance('#kt_header_search')

    // Search handler
    searchObject!.on('kt.search.process', (search: SearchComponent) => {
      const searchValue = search.inputElement.value
      setTimeout(async function () {

        let appendString = `search=${searchValue}&role=SuperAdmin`
        const usersResponse = await getUsersFromUrl(appendString, token)

        setSuperadmins(usersResponse.data.items)

        if (usersResponse.data.items.length === 0) {
          // Hide results
          resultsElement.current!.classList.add('d-none')
          // Show empty message
          emptyElement.current!.classList.remove('d-none')
        } else {
          // Show results
          resultsElement.current!.classList.remove('d-none')
          // Hide empty message
          emptyElement.current!.classList.add('d-none')
        }

        // Complete search
        search.complete()
      }, 1500)
    })

    // Clear handler
    searchObject!.on('kt.search.clear', (search: SearchComponent) => {
      // Show recently viewed
      // suggestionsElement.current!.classList.remove('d-none')
      // Hide results
      resultsElement.current!.classList.add('d-none')
      // Hide empty message
      emptyElement.current!.classList.add('d-none')
    })
  }, [token])

  return (
    <>
      <div
        id='kt_header_search'
        className='d-flex align-items-stretch'
        data-kt-search-keypress='true'
        data-kt-search-min-length='2'
        data-kt-search-enter='enter'
        data-kt-search-layout='menu'
        data-kt-menu-trigger='auto'
        data-kt-menu-overflow='false'
        data-kt-menu-permanent='true'
        data-kt-menu-placement='bottom-start'
        ref={element}
      >
        <div
          className='d-flex align-items-center w-100'
          data-kt-search-element='toggle'
          id='kt_header_search_toggle'
        >
          <input
            ref={selectedInputRef}
            type='text'
            className='form-control form-control-lg form-control-solid'
            placeholder=''
          />
        </div>

        <div
          data-kt-search-element='content'
          className='menu menu-sub menu-sub-dropdown p-7 w-325px w-md-500px'
        >
          <div
            className={``}
            ref={wrapperElement}
            data-kt-search-element='wrapper'
          >
            <form
              data-kt-search-element='form'
              className='w-100 position-relative mb-3'
              autoComplete='off'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
              />

              <input
                type='text'
                className='form-control form-control-flush ps-10'
                name='search'
                placeholder='Search...'
                data-kt-search-element='input'
              />

              <span
                className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1'
                data-kt-search-element='spinner'
              >
                <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
              </span>

              <span
                className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none'
                data-kt-search-element='clear'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 svg-icon-lg-1 me-0'
                />
              </span>
            </form>

            <div ref={resultsElement} data-kt-search-element='results' className='d-none'>
              <div className='scroll-y mh-200px mh-lg-350px'>
                {
                  superadmins.map(superadmin => {
                    return <Link
                      key={superadmin.id}
                      to='#'
                      className='d-flex text-dark text-hover-primary align-items-center mb-5'
                      onClick={() => handleItemClick(superadmin.id, superadmin.email)}
                    >
                      <div className='symbol symbol-40px me-4'>
                        <img src={(superadmin?.avatarUrl == null || superadmin.avatarUrl?.includes('media/icons/duotune/general/create-team-icon')) ? toAbsoluteUrl('/media/avatars/blank.png') : superadmin.avatarUrl} alt="Avatar" width="40" />
                      </div>

                      <div className='d-flex flex-column justify-content-start fw-bold'>
                        <span className='fs-6 fw-bold'>{superadmin.firstName} {superadmin.lastName}</span>
                        <span className='fs-7 fw-bold'>{superadmin.email}</span>
                      </div>
                    </Link>
                  })
                }
              </div>
            </div>

            <div ref={emptyElement} data-kt-search-element='empty' className='text-center d-none'>
              <div className='pt-10 pb-10'>
                <KTSVG
                  path='/media/icons/duotune/files/fil024.svg'
                  className='svg-icon-4x opacity-50'
                />
              </div>

              <div className='pb-15 fw-bold'>
                <h3 className='text-gray-600 fs-5 mb-2'>No result found</h3>
                <div className='text-muted fs-7'>Please try again with a different query</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { SuperadminSearch }
